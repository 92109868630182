:root {
  --core-colorswhite: #fff;
  --dark: #072125;
  --neutrals-0: #fff;
  --white: #fff;
}

.component {
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 16px 8px 20px;
  display: inline-flex;
  position: relative;
}

.component .home {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Public Sans, Helvetica;
  font-size: 14px;
  line-height: normal;
  position: relative;
}

.component .expand-more-black {
  width: 16px;
  height: 16px;
  position: relative;
}

.component.frame-140 {
  background-color: #e7e7e7bf;
}

.component.frame-141 {
  background-color: #f5ebfcbf;
}

.component.frame-140 .home, .component.frame-137 .home {
  color: #0d0d0d;
  font-weight: 400;
}

.component.frame-141 .home {
  color: #7c2fb2;
  font-weight: 500;
}

.property-logo {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.property-wrapper {
  height: 34px;
  overflow: hidden;
}

.property-wrapper .group {
  width: 134px;
  height: 134px;
  position: relative;
}

.property-wrapper .text-wrapper {
  color: #0d0d0d;
  letter-spacing: -.7px;
  white-space: nowrap;
  height: 34px;
  font-family: Public Sans, Helvetica;
  font-size: 28px;
  font-weight: 700;
  line-height: 33.6px;
  position: absolute;
  top: 0;
  left: 0;
}

.property-wrapper .div {
  color: #0d0d0d;
  letter-spacing: -.7px;
  white-space: nowrap;
  height: 34px;
  font-family: Public Sans, Helvetica;
  font-size: 28px;
  font-weight: 700;
  line-height: 33.6px;
  position: absolute;
  top: 50px;
  left: 0;
}

.property-wrapper .text-wrapper-2 {
  color: #0d0d0d;
  letter-spacing: -.7px;
  white-space: nowrap;
  height: 34px;
  font-family: Public Sans, Helvetica;
  font-size: 28px;
  font-weight: 700;
  line-height: 33.6px;
  position: absolute;
  top: 100px;
  left: 0;
}

.property-wrapper.one {
  width: 88px;
}

.property-wrapper.three {
  width: 128px;
}

.property-wrapper.two {
  width: 114px;
}

.property-wrapper.four {
  width: 88px;
}

.property-wrapper.one .group {
  top: -100px;
}

.property-wrapper.three .group {
  top: -50px;
}

.property-wrapper.four .group {
  top: -100px;
}

.case {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

body {
  overflow-x: hidden;
}

.case .div-2 {
  background-color: #fff;
  width: 1920px;
  height: 16819px;
  position: relative;
}

.case .the-challenge {
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 1920px;
  padding: 80px 120px;
  display: flex;
  position: absolute;
  top: 1677px;
  left: 0;
}

.case .frame {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 48px;
  width: 100%;
  display: flex;
  position: relative;
}

.case .frame-2 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.case .text-wrapper-3 {
  color: #676768;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  position: relative;
}

.case .p {
  color: #676768;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 44.8px;
  position: relative;
}

.case .div-wrapper {
  background-color: #2f2f2f;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 20px 32px;
  display: inline-flex;
  position: relative;
}

.case .text-wrapper-4 {
  color: #fff;
  letter-spacing: -.24px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  text-decoration: underline;
  position: relative;
}

.case .frame-3 {
  background-color: #f7fafd;
  width: 1921px;
  height: 2727px;
  position: absolute;
  top: 2176px;
  left: -1px;
}

.case .overlap-group {
  width: 1920px;
  height: 2579px;
  position: absolute;
  top: 148px;
  left: 1px;
}

.case .vector {
  width: 1912px;
  height: 301px;
  position: absolute;
  top: 2278px;
  left: 0;
}

.case .img {
  width: 1920px;
  height: 352px;
  position: absolute;
  top: 2227px;
  left: 0;
}

.case .home-page {
  width: 1440px;
  height: 2579px;
  position: absolute;
  top: 0;
  left: 239px;
}

.case .text-wrapper-5 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 28px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  position: absolute;
  top: 67px;
  left: 241px;
}

.case .element {
  width: 1920px;
  height: 1922px;
  position: absolute;
  top: 5439px;
  left: 0;
}

.case .overlap {
  width: 222px;
  height: 37px;
  position: absolute;
  top: -86px;
  left: 120px;
}

.case .text-wrapper-6 {
  color: #2f2f2f;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Neue Haas Grotesk Display Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.case .overlap-group-wrapper {
  width: 896px;
  height: 1180px;
  position: absolute;
  top: 0;
  left: 1024px;
  overflow: hidden;
}

.case .overlap-group-2 {
  background-color: #f5ebfc;
  border-radius: 2px;
  width: 1020px;
  height: 1180px;
  position: relative;
}

.case .overlap-2 {
  width: 697px;
  height: 769px;
  position: absolute;
  top: 89px;
  left: 156px;
}

.case .text-wrapper-7 {
  color: #0d0d0d;
  letter-spacing: -2.75px;
  white-space: nowrap;
  font-family: Public Sans, Helvetica;
  font-size: 549px;
  font-weight: 400;
  line-height: 768.6px;
  position: absolute;
  top: 0;
  left: 0;
}

.case .text-wrapper-8 {
  color: #0d0d0d;
  letter-spacing: -.16px;
  white-space: nowrap;
  font-family: Public Sans, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 44.8px;
  position: absolute;
  top: 39px;
  left: 9px;
}

.case .text-wrapper-9 {
  color: #0d0d0d;
  letter-spacing: 0;
  width: 693px;
  font-family: Public Sans, Helvetica;
  font-size: 48px;
  font-weight: 300;
  line-height: 67.2px;
  position: absolute;
  top: 858px;
  left: 156px;
}

.case .frame-4 {
  flex-direction: column;
  align-items: flex-start;
  width: 960px;
  height: 1180px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.case .frame-5 {
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 639px;
  display: flex;
  position: relative;
}

.case .frame-wrapper {
  background-color: #7c2fb2;
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 640px;
  padding: 80px 64px;
  display: flex;
  position: relative;
}

.case .frame-6 {
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.case .text-wrapper-10 {
  color: var(--core-colorswhite);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.case .frame-7 {
  flex: none;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.case .text-wrapper-11 {
  color: var(--core-colorswhite);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.case .frame-8 {
  background-color: #1269b7;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  padding: 80px 64px;
  display: flex;
  position: relative;
}

.case .frame-9 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.case .frame-10 {
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 341px;
  display: flex;
  position: relative;
}

.case .frame-11 {
  background-color: #50d4f2;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  padding: 64px;
  display: flex;
  position: relative;
}

.case .frame-12 {
  background-color: coral;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  padding: 64px;
  display: flex;
  position: relative;
}

.case .frame-13 {
  background-color: #73d9bc;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  padding: 64px;
  display: flex;
  position: relative;
}

.case .frame-14 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.case .frame-15 {
  background-color: #fffbdb;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 32px;
  height: 200px;
  padding: 56px 64px;
  display: flex;
  position: relative;
}

.case .text-wrapper-12 {
  color: #0d0d0d;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.case .element-df {
  color: #0d0d0d;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.case .frame-16 {
  background-color: #fff;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 32px;
  height: 200px;
  padding: 56px 64px;
  display: flex;
  position: relative;
}

.case .text-wrapper-13 {
  color: #000;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.case .text-wrapper-14 {
  color: #000;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.case .frame-17 {
  background-color: #0d0d0d;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 32px;
  height: 200px;
  padding: 56px 64px;
  display: flex;
  position: relative;
}

.case .text-wrapper-15 {
  color: #fff;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.case .element-ddd {
  color: #fff;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.case .frame-18 {
  background-color: #f8f8f8;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 80px;
  width: 1920px;
  height: 678px;
  padding: 120px;
  display: flex;
  position: absolute;
  top: 1244px;
  left: 0;
}

.case .img-2 {
  width: 322px;
  height: 200px;
  margin-top: -21px;
  position: relative;
}

.case .img-3 {
  width: 322px;
  height: 200px;
  margin-bottom: -21px;
  position: relative;
}

.case .frame-19 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 1920px;
  padding: 120px 160px;
  display: flex;
  position: absolute;
  top: 4905px;
  left: 0;
}

.case .text-wrapper-16 {
  color: #2f2f2f;
  letter-spacing: -.3px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 38.4px;
  position: relative;
}

.case .we-spruced-up-the {
  color: #676768;
  letter-spacing: -.3px;
  width: 1272px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 40px;
  font-weight: 400;
  line-height: 56px;
  position: relative;
}

.case .digited-business {
  background-color: #fff;
  border: 8px solid #e6e6e6;
  border-radius: 8px;
  width: 821px;
  height: 3168px;
  position: absolute;
  top: 7433px;
  left: 987px;
  overflow: hidden;
}

.case .overlap-3 {
  width: 805px;
  height: 1635px;
  position: absolute;
  top: 0;
  left: 0;
}

.case .section-main {
  width: 805px;
  height: 598px;
  position: absolute;
  top: 41px;
  left: 1px;
}

.case .frame-20 {
  background-color: #fbfbfb;
  width: 805px;
  height: 1034px;
  position: absolute;
  top: 600px;
  left: 0;
}

.case .text-wrapper-17 {
  color: #0d0d0d;
  letter-spacing: -.33px;
  width: 273px;
  height: 44px;
  font-family: Public Sans, Helvetica;
  font-size: 18.4px;
  font-weight: 800;
  line-height: 22.1px;
  position: absolute;
  top: 245px;
  left: 45px;
}

.case .text-wrapper-18 {
  color: #0d0d0d;
  letter-spacing: -.33px;
  width: 250px;
  height: 22px;
  font-family: Public Sans, Helvetica;
  font-size: 18.4px;
  font-weight: 800;
  line-height: 22.1px;
  position: absolute;
  top: 552px;
  left: 45px;
}

.case .text-wrapper-19 {
  color: #606060;
  letter-spacing: 0;
  width: 273px;
  font-family: Public Sans, Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  line-height: 15.9px;
  position: absolute;
  top: 583px;
  left: 45px;
}

.case .we-succeed-only-when {
  color: #606060;
  letter-spacing: 0;
  width: 273px;
  font-family: Public Sans, Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  line-height: 15.9px;
  position: absolute;
  top: 854px;
  left: 45px;
}

.case .text-wrapper-20 {
  color: #0d0d0d;
  letter-spacing: -.33px;
  width: 300px;
  height: 44px;
  font-family: Public Sans, Helvetica;
  font-size: 18.4px;
  font-weight: 800;
  line-height: 22.1px;
  position: absolute;
  top: 796px;
  left: 45px;
}

.case .frame-21 {
  align-items: flex-end;
  gap: 4.47px;
  display: inline-flex;
  position: absolute;
  top: 933px;
  left: 45px;
}

.case .text-wrapper-21 {
  color: #af62e5;
  letter-spacing: .45px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.38px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 700;
  line-height: 10.7px;
  position: relative;
}

.case .arrow-forward {
  width: 11.18px;
  height: 11.18px;
  position: relative;
}

.case .text-wrapper-22 {
  color: #606060;
  letter-spacing: 0;
  width: 244px;
  font-family: Public Sans, Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  line-height: 15.9px;
  position: absolute;
  top: 299px;
  left: 45px;
}

.case .section-image {
  width: 335px;
  height: 224px;
  position: absolute;
  top: 205px;
  left: 425px;
}

.case .section-image-2 {
  width: 335px;
  height: 223px;
  position: absolute;
  top: 480px;
  left: 425px;
}

.case .section-image-3 {
  width: 335px;
  height: 223px;
  position: absolute;
  top: 755px;
  left: 425px;
}

.case .frame-22 {
  flex-direction: column;
  align-items: center;
  gap: 17.89px;
  display: inline-flex;
  position: absolute;
  top: 56px;
  left: 120px;
}

.case .type-wrapper {
  background-color: #9c9fa11f;
  border-radius: 27.95px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5.59px;
  padding: 4.47px 13.42px;
  display: inline-flex;
  position: relative;
}

.case .type {
  color: #0d0d0d;
  letter-spacing: -.18px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.56px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 700;
  line-height: 12.5px;
  position: relative;
}

.case .frame-23 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8.95px;
  display: inline-flex;
  position: relative;
}

.case .type-2 {
  color: #0000;
  letter-spacing: -.81px;
  text-align: center;
  width: 565.78px;
  margin-top: -.56px;
  font-family: Public Sans, Helvetica;
  font-size: 26.8px;
  font-weight: 400;
  line-height: 32.2px;
  position: relative;
}

.case .span {
  color: #0d0d0d;
  font-weight: 700;
}

.case .text-wrapper-23 {
  color: #0d0d0d;
  font-weight: 800;
}

.case .text-wrapper-24 {
  color: #af62e5;
  font-weight: 800;
}

.case .frame-24 {
  flex-direction: column;
  align-items: center;
  gap: 17.89px;
  display: inline-flex;
  position: absolute;
  top: 94px;
  left: 229px;
}

.case .start-teaching-share {
  color: #fff;
  letter-spacing: -.81px;
  text-align: center;
  width: 348.3px;
  margin-top: -.56px;
  font-family: Public Sans, Helvetica;
  font-size: 26.8px;
  font-weight: 800;
  line-height: 32.2px;
  position: relative;
}

.case .text-wrapper-25 {
  color: #fff;
  letter-spacing: -.16px;
  text-align: center;
  width: 291.28px;
  font-family: Public Sans, Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  line-height: 14.9px;
  position: relative;
}

.case .buttons {
  all: unset;
  box-sizing: border-box;
  background: linear-gradient(#1269b7 0%, #af62e5 100%);
  border-radius: 2.24px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5.59px;
  width: 122.44px;
  padding: 8.95px 13.42px;
  display: flex;
  position: relative;
}

.case .text-wrapper-26 {
  color: #fff;
  letter-spacing: -.18px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.56px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 700;
  line-height: 12.5px;
  position: relative;
}

.case .frame-25 {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.59px;
  padding: 8.95px 44.73px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.case .frame-26 {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 715.61px;
  display: flex;
  position: relative;
}

.case .frame-27 {
  flex: none;
  align-items: center;
  gap: 17.89px;
  display: inline-flex;
  position: relative;
}

.case .image-wrapper {
  width: 72.12px;
  height: 20.69px;
  position: relative;
}

.case .image {
  object-fit: cover;
  width: 72px;
  height: 20px;
  position: absolute;
  top: 1px;
  left: 0;
}

.case .frame-28 {
  align-items: center;
  gap: 20.13px;
  width: 150.39px;
  display: flex;
  position: relative;
}

.case .component-5 {
  border-radius: 4.47px !important;
  flex: none !important;
  gap: 5.59px !important;
  height: 19.57px !important;
  padding: 4.47px 6.71px !important;
}

.case .component-instance {
  white-space: unset !important;
  margin-top: -.75px !important;
  font-size: 8.9px !important;
}

.case .component-5-instance {
  width: 8.95px !important;
  height: 8.95px !important;
}

.case .frame-29 {
  background: linear-gradient(#1269b7 0%, #af62e5 100%);
  border-radius: 2.24px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5.59px;
  padding: 5.59px 8.95px;
  display: inline-flex;
  position: relative;
}

.case .CT-as {
  background-image: url("ctas-4.34674b77.png");
  background-position: 50%;
  background-size: cover;
  width: 805px;
  height: 226px;
  position: absolute;
  top: 2702px;
  left: 0;
}

.case .text-wrapper-27 {
  color: var(--neutrals-0);
  letter-spacing: -.81px;
  white-space: nowrap;
  height: 32px;
  font-family: Public Sans, Helvetica;
  font-size: 26.8px;
  font-weight: 800;
  line-height: 32.2px;
  position: absolute;
  top: 57px;
  left: 249px;
}

.case .text-wrapper-28 {
  color: #fff;
  letter-spacing: -.16px;
  white-space: nowrap;
  height: 15px;
  font-family: Public Sans, Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  line-height: 14.9px;
  position: absolute;
  top: 97px;
  left: 344px;
}

.case .buttons-2 {
  background-color: var(--white);
  border: .56px solid #cfcfcf;
  border-radius: 2.24px;
  justify-content: center;
  align-items: center;
  gap: 5.59px;
  padding: 8.95px 13.42px;
  display: inline-flex;
  position: absolute;
  top: 134px;
  left: 365px;
  overflow: hidden;
}

.case .text-wrapper-29 {
  color: #3d3d3d;
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.56px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 12.9px;
  position: relative;
}

.case .frame-30 {
  background-color: #0d0d0d;
  width: 805px;
  height: 224px;
  position: absolute;
  top: 2928px;
  left: 0;
}

.case .mask-group-wrapper {
  background-image: url("image-3-13.c47a84fb.png");
  background-position: 50%;
  background-size: cover;
  width: 100px;
  height: 28px;
  position: absolute;
  top: 34px;
  left: 67px;
}

.case .mask-group {
  width: 100px;
  height: 28px;
  position: absolute;
  top: 0;
  left: 0;
}

.case .company-links {
  width: 69px;
  height: 113px;
  position: absolute;
  top: 34px;
  left: 409px;
  overflow: hidden;
}

.case .text-wrapper-30 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Public Sans, Helvetica;
  font-size: 10.1px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.case .text-wrapper-31 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 13.4px;
  position: absolute;
  top: 25px;
  left: 0;
}

.case .text-wrapper-32 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 13.4px;
  position: absolute;
  top: 49px;
  left: 0;
}

.case .resources-links {
  width: 72px;
  height: 113px;
  position: absolute;
  top: 34px;
  left: 492px;
  overflow: hidden;
}

.case .text-wrapper-33 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 13.4px;
  position: absolute;
  top: 74px;
  left: 0;
}

.case .frame-31 {
  flex-direction: column;
  align-items: flex-start;
  gap: 13.42px;
  height: 113px;
  display: inline-flex;
  position: absolute;
  top: 34px;
  left: 630px;
}

.case .text-wrapper-34 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.56px;
  font-family: Public Sans, Helvetica;
  font-size: 10.1px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.case .frame-32 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 11.18px;
  display: inline-flex;
  position: relative;
}

.case .frame-33 {
  flex: none;
  align-items: center;
  gap: 5.59px;
  display: inline-flex;
  position: relative;
}

.case .icon-instance-node {
  width: 13.42px;
  height: 13.42px;
  position: relative;
}

.case .text-wrapper-35 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.35px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 13.4px;
  position: relative;
}

.case .frame-34 {
  flex: none;
  align-items: flex-start;
  gap: 5.59px;
  display: inline-flex;
  position: relative;
}

.case .t-hub-gachibowli {
  color: #fff;
  letter-spacing: 0;
  width: 110.7px;
  margin-top: -.56px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 13.4px;
  position: relative;
}

.case .text-wrapper-36 {
  color: #fff;
  letter-spacing: 0;
  width: 171px;
  font-family: Public Sans, Helvetica;
  font-size: 7.8px;
  font-weight: 400;
  line-height: 11.7px;
  position: absolute;
  top: 74px;
  left: 67px;
}

.case .frame-35 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8.95px;
  width: 716px;
  display: flex;
  position: absolute;
  top: 179px;
  left: 45px;
}

.case .vector-2 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -.28px;
  position: relative;
}

.case .frame-36 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.case .text-wrapper-37 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Public Sans, Helvetica;
  font-size: 7.8px;
  font-weight: 400;
  line-height: 11.7px;
  position: relative;
}

.case .frame-37 {
  flex: none;
  align-items: center;
  gap: 13.42px;
  display: inline-flex;
  position: relative;
}

.case .frame-38 {
  background-color: #fff;
  width: 805px;
  height: 520px;
  position: absolute;
  top: 1635px;
  left: 0;
}

.case .frame-39 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.18px;
  width: 190px;
  display: flex;
  position: absolute;
  top: 182px;
  left: 67px;
}

.case .section-icon {
  width: 20.13px;
  height: 20.13px;
  position: relative;
}

.case .frame-40 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6.71px;
  width: 169.96px;
  display: flex;
  position: relative;
}

.case .type-3 {
  color: #0d0d0d;
  letter-spacing: -.15px;
  width: 190.08px;
  margin-top: -.56px;
  margin-right: -20.13px;
  font-family: Public Sans, Helvetica;
  font-size: 12.9px;
  font-weight: 700;
  line-height: 15.4px;
  position: relative;
}

.case .text-wrapper-38 {
  color: #858585;
  letter-spacing: -.04px;
  width: 190.08px;
  margin-right: -20.13px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 12.9px;
  position: relative;
}

.case .frame-41 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.18px;
  width: 190px;
  display: flex;
  position: absolute;
  top: 342px;
  left: 67px;
}

.case .frame-42 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.18px;
  width: 190px;
  display: flex;
  position: absolute;
  top: 342px;
  left: 307px;
}

.case .frame-43 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.18px;
  width: 190px;
  display: flex;
  position: absolute;
  top: 342px;
  left: 548px;
}

.case .frame-44 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.18px;
  width: 190px;
  display: flex;
  position: absolute;
  top: 182px;
  left: 307px;
}

.case .frame-45 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6.71px;
  width: 170.52px;
  display: flex;
  position: relative;
}

.case .type-4 {
  color: #0d0d0d;
  letter-spacing: -.15px;
  width: 190.08px;
  margin-top: -.56px;
  margin-right: -19.57px;
  font-family: Public Sans, Helvetica;
  font-size: 12.9px;
  font-weight: 700;
  line-height: 15.4px;
  position: relative;
}

.case .text-wrapper-39 {
  color: #858585;
  letter-spacing: -.04px;
  width: 190.08px;
  margin-right: -19.57px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 12.9px;
  position: relative;
}

.case .frame-46 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.18px;
  width: 190px;
  display: flex;
  position: absolute;
  top: 182px;
  left: 548px;
}

.case .frame-47 {
  flex-direction: column;
  align-items: center;
  gap: 17.89px;
  display: inline-flex;
  position: absolute;
  top: 45px;
  left: 151px;
}

.case .type-5 {
  color: #0d0d0d;
  letter-spacing: -.81px;
  text-align: center;
  width: 503.16px;
  margin-top: -.56px;
  font-family: Public Sans, Helvetica;
  font-size: 26.8px;
  font-weight: 800;
  line-height: 32.2px;
  position: relative;
}

.case .overlap-4 {
  width: 805px;
  height: 526px;
  position: absolute;
  top: 2155px;
  left: 0;
}

.case .frame-48 {
  background-color: #fff;
  width: 805px;
  height: 295px;
  position: absolute;
  top: 0;
  left: 0;
}

.case .overlap-5 {
  width: 700px;
  height: 165px;
  position: absolute;
  top: 108px;
  left: 53px;
}

.case .frame-49 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.18px;
  width: 190px;
  display: flex;
  position: absolute;
  top: 0;
  left: 255px;
}

.case .frame-50 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.18px;
  width: 190px;
  display: flex;
  position: absolute;
  top: 0;
  left: 495px;
}

.case .section-main-2 {
  width: 700px;
  height: 165px;
  position: absolute;
  top: 0;
  left: 0;
}

.case .text-wrapper-40 {
  color: #000;
  letter-spacing: -.81px;
  text-align: center;
  width: 391px;
  height: 32px;
  font-family: Public Sans, Helvetica;
  font-size: 26.8px;
  font-weight: 800;
  line-height: 32.2px;
  position: absolute;
  top: 44px;
  left: 207px;
}

.case .text-wrapper-41 {
  color: #0d0d0d;
  letter-spacing: -.2px;
  text-align: center;
  width: 391px;
  height: 19px;
  font-family: Public Sans, Helvetica;
  font-size: 13.4px;
  font-weight: 400;
  line-height: 18.8px;
  position: absolute;
  top: 81px;
  left: 207px;
}

.case .text-container {
  border-radius: 6.71px;
  width: 718px;
  height: 243px;
  position: absolute;
  top: 283px;
  left: 41px;
  overflow: hidden;
}

.case .text-wrapper-42 {
  color: var(--dark);
  letter-spacing: -.33px;
  text-align: center;
  width: 391px;
  font-family: Public Sans, Helvetica;
  font-size: 18.4px;
  font-weight: 500;
  line-height: 22.1px;
  position: absolute;
  top: 63px;
  left: 164px;
}

.case .frame-51 {
  width: 23px;
  height: 18px;
  position: absolute;
  top: 27px;
  left: 348px;
}

.case .frame-52 {
  align-items: center;
  gap: 6.71px;
  display: inline-flex;
  position: absolute;
  top: 153px;
  left: 279px;
}

.case .ellipse-wrapper {
  width: 35.78px;
  height: 35.78px;
  position: relative;
}

.case .ellipse {
  object-fit: cover;
  width: 34px;
  height: 34px;
  position: absolute;
  top: 1px;
  left: 1px;
}

.case .frame-53 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 2.24px;
  display: inline-flex;
  position: relative;
}

.case .text-wrapper-43 {
  color: var(--dark);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.56px;
  font-family: Public Sans, Helvetica;
  font-size: 10.1px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.case .text-wrapper-44 {
  color: var(--dark);
  letter-spacing: 0;
  width: 120.76px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 13.4px;
  position: relative;
}

.case .frame-54 {
  align-items: flex-start;
  gap: 2.24px;
  display: inline-flex;
  position: absolute;
  top: 224px;
  left: 351px;
}

.case .ellipse-2 {
  background-color: #d9d9d9;
  border-radius: 2.24px;
  width: 4.47px;
  height: 4.47px;
  position: relative;
}

.case .ellipse-3 {
  background-color: #1269b7;
  border-radius: 2.24px;
  width: 4.47px;
  height: 4.47px;
  position: relative;
}

.case .digit-ed {
  width: 821px;
  height: 3722px;
  position: absolute;
  top: 7547px;
  left: 112px;
}

.case .digited-campus {
  background-color: #fff;
  border: 8px solid #e6e6e6;
  border-radius: 8px;
  width: 821px;
  height: 2659px;
  position: absolute;
  top: 10649px;
  left: 987px;
  overflow: hidden;
}

.case .section-main-3 {
  width: 804px;
  height: 616px;
  position: absolute;
  top: 41px;
  left: 1px;
}

.case .frame-55 {
  flex-direction: column;
  align-items: center;
  gap: 17.89px;
  display: inline-flex;
  position: absolute;
  top: 94px;
  left: 243px;
}

.case .text-wrapper-45 {
  color: #fff;
  letter-spacing: -.81px;
  text-align: center;
  width: 320.35px;
  margin-top: -.56px;
  font-family: Public Sans, Helvetica;
  font-size: 26.8px;
  font-weight: 800;
  line-height: 32.2px;
  position: relative;
}

.case .enable-your {
  color: #fff;
  letter-spacing: -.16px;
  text-align: center;
  width: 297.98px;
  font-family: Public Sans, Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  line-height: 14.9px;
  position: relative;
}

.case .buttons-3 {
  background: linear-gradient(#1269b7 0%, #af62e5 100%);
  border-radius: 2.24px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5.59px;
  width: 122.44px;
  padding: 8.95px 17.89px;
  display: flex;
  position: relative;
}

.case .text-wrapper-46 {
  color: #0d0d0d;
  letter-spacing: -.33px;
  width: 250px;
  height: 44px;
  font-family: Public Sans, Helvetica;
  font-size: 18.4px;
  font-weight: 800;
  line-height: 22.1px;
  position: absolute;
  top: 529px;
  left: 45px;
}

.case .text-wrapper-47 {
  color: #606060;
  letter-spacing: -.04px;
  width: 273px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 12.9px;
  position: absolute;
  top: 583px;
  left: 45px;
}

.case .text-wrapper-48 {
  color: #606060;
  letter-spacing: -.04px;
  width: 273px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 12.9px;
  position: absolute;
  top: 850px;
  left: 45px;
}

.case .frame-56 {
  align-items: flex-end;
  gap: 4.47px;
  display: inline-flex;
  position: absolute;
  top: 907px;
  left: 45px;
}

.case .effortlessly-deliver {
  color: #606060;
  letter-spacing: -.04px;
  width: 244px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 12.9px;
  position: absolute;
  top: 299px;
  left: 45px;
}

.case .frame-57 {
  flex-direction: column;
  align-items: center;
  gap: 17.89px;
  display: inline-flex;
  position: absolute;
  top: 56px;
  left: 212px;
}

.case .type-6 {
  color: #0d0d0d;
  letter-spacing: -.81px;
  text-align: center;
  width: 380.17px;
  margin-top: -.56px;
  font-family: Public Sans, Helvetica;
  font-size: 26.8px;
  font-weight: 800;
  line-height: 32.2px;
  position: relative;
}

.case .frame-58 {
  align-items: flex-end;
  gap: 4.47px;
  display: inline-flex;
  position: absolute;
  top: 369px;
  left: 45px;
}

.case .frame-59 {
  align-items: flex-end;
  gap: 4.47px;
  display: inline-flex;
  position: absolute;
  top: 640px;
  left: 45px;
}

.case .CT-as-2 {
  background-image: url("ctas-3.f67968a2.png");
  background-position: 50%;
  background-size: cover;
  width: 805px;
  height: 226px;
  position: absolute;
  top: 2416px;
  left: 0;
}

.case .text-wrapper-49 {
  color: var(--neutrals-0);
  letter-spacing: -.81px;
  white-space: nowrap;
  height: 32px;
  font-family: Public Sans, Helvetica;
  font-size: 26.8px;
  font-weight: 800;
  line-height: 32.2px;
  position: absolute;
  top: 57px;
  left: 212px;
}

.case .find-out-how-it-can {
  color: #fff;
  letter-spacing: -.16px;
  white-space: nowrap;
  height: 15px;
  font-family: Public Sans, Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  line-height: 14.9px;
  position: absolute;
  top: 97px;
  left: 171px;
}

.case .buttons-4 {
  background-color: var(--white);
  border: .56px solid #cfcfcf;
  border-radius: 2.24px;
  justify-content: center;
  align-items: center;
  gap: 5.59px;
  padding: 8.95px 13.42px;
  display: inline-flex;
  position: absolute;
  top: 134px;
  left: 350px;
  overflow: hidden;
}

.case .frame-60 {
  background-color: #0d0d0d;
  width: 805px;
  height: 224px;
  position: absolute;
  top: 2643px;
  left: 0;
}

.case .img-wrapper {
  background-image: url("mask-group-8.bcd21af7.png");
  background-position: 50%;
  background-size: cover;
  width: 100px;
  height: 28px;
  position: absolute;
  top: -5719px;
  left: 32px;
}

.case .company-links-2 {
  width: 69px;
  height: 113px;
  position: absolute;
  top: 34px;
  left: 409px;
}

.case .mail-outline-black {
  width: 13.42px;
  height: 13.42px;
  margin-top: -5811.73px;
  margin-left: -665.07px;
  position: relative;
}

.case .place-black {
  width: 13.42px;
  height: 13.42px;
  margin-top: -5836.33px;
  margin-left: -665.07px;
  position: relative;
}

.case .vector-3 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -5931.67px;
  margin-left: -79.73px;
  position: relative;
}

.case .frame-61 {
  background-color: #fff;
  width: 805px;
  height: 506px;
  position: absolute;
  top: 1635px;
  left: 0;
}

.case .frame-62 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.18px;
  width: 190px;
  display: flex;
  position: absolute;
  top: 201px;
  left: 67px;
}

.case .frame-63 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.18px;
  width: 190px;
  display: flex;
  position: absolute;
  top: 353px;
  left: 67px;
}

.case .frame-64 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.18px;
  width: 190px;
  display: flex;
  position: absolute;
  top: 353px;
  left: 307px;
}

.case .frame-65 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.18px;
  width: 190px;
  display: flex;
  position: absolute;
  top: 353px;
  left: 548px;
}

.case .frame-66 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.18px;
  width: 190px;
  display: flex;
  position: absolute;
  top: 201px;
  left: 307px;
}

.case .frame-67 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.18px;
  width: 190px;
  display: flex;
  position: absolute;
  top: 201px;
  left: 548px;
}

.case .frame-68 {
  flex-direction: column;
  align-items: center;
  gap: 17.89px;
  display: inline-flex;
  position: absolute;
  top: 45px;
  left: 144px;
}

.case .type-7 {
  color: #0d0d0d;
  letter-spacing: -.81px;
  text-align: center;
  width: 447.26px;
  margin-top: -.56px;
  font-family: Public Sans, Helvetica;
  font-size: 26.8px;
  font-weight: 800;
  line-height: 32.2px;
  position: relative;
}

.case .text-wrapper-50 {
  color: #0d0d0d;
  letter-spacing: -.15px;
  text-align: center;
  width: 505.4px;
  font-family: Public Sans, Helvetica;
  font-size: 12.9px;
  font-weight: 400;
  line-height: 18px;
  position: relative;
}

.case .text-container-2 {
  border-radius: 6.71px;
  width: 718px;
  height: 243px;
  position: absolute;
  top: 2152px;
  left: 41px;
  overflow: hidden;
}

.case .about-us {
  background-color: #fff;
  border: 8px solid #e6e6e6;
  border-radius: 8px;
  width: 821px;
  height: 1930px;
  position: absolute;
  top: 11317px;
  left: 112px;
  overflow: hidden;
}

.case .overlap-6 {
  width: 805px;
  height: 309px;
  position: absolute;
  top: 0;
  left: 0;
}

.case .section-BG-image {
  width: 805px;
  height: 267px;
  position: absolute;
  top: 41px;
  left: 0;
}

.case .buttons-5 {
  background-color: #0d0d0d;
  border-radius: 2.24px;
  justify-content: center;
  align-items: center;
  gap: 5.59px;
  width: 122px;
  padding: 8.94px 13.42px;
  display: flex;
  position: absolute;
  top: 230px;
  left: 341px;
}

.case .text-wrapper-51 {
  color: #fff;
  letter-spacing: -.81px;
  text-align: center;
  width: 475px;
  height: 96px;
  font-family: Public Sans, Helvetica;
  font-size: 26.8px;
  font-weight: 800;
  line-height: 32.2px;
  position: absolute;
  top: 121px;
  left: 165px;
}

.case .frame-69 {
  background-color: #f6f6f61f;
  border-radius: 27.95px;
  justify-content: center;
  align-items: center;
  gap: 5.59px;
  padding: 4.47px 13.42px;
  display: inline-flex;
  position: absolute;
  top: 86px;
  left: 363px;
}

.case .type-8 {
  color: #fff;
  letter-spacing: -.18px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.56px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 700;
  line-height: 12.5px;
  position: relative;
}

.case .frame-70 {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.59px;
  padding: 8.94px 44.72px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.case .frame-71 {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 715.56px;
  display: flex;
  position: relative;
}

.case .frame-72 {
  width: 72.11px;
  height: 20.68px;
  position: relative;
}

.case .frame-73 {
  align-items: center;
  gap: 20.12px;
  width: 150.38px;
  display: flex;
  position: relative;
}

.case .design-component-instance-node {
  width: 8.94px !important;
  height: 8.94px !important;
}

.case .frame-74 {
  background: linear-gradient(#1269b7 0%, #af62e5 100%);
  border-radius: 2.24px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 5.59px;
  padding: 5.59px 8.94px;
  display: inline-flex;
  position: relative;
}

.case .overlap-7 {
  background-color: #fffbdb;
  width: 805px;
  height: 240px;
  position: absolute;
  top: 550px;
  left: 0;
}

.case .frame-75 {
  align-items: flex-start;
  gap: 74.35px;
  display: inline-flex;
  position: relative;
  top: 45px;
  left: 68px;
}

.case .text-wrapper-52 {
  color: #0d0d0d;
  letter-spacing: -.81px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.56px;
  font-family: Public Sans, Helvetica;
  font-size: 26.8px;
  font-weight: 800;
  line-height: 32.2px;
  position: relative;
}

.case .frame-76 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 13.42px;
  display: inline-flex;
  position: relative;
}

.case .text-wrapper-53 {
  color: #0d0d0d;
  letter-spacing: -.15px;
  width: 472.38px;
  margin-top: -.56px;
  font-family: Public Sans, Helvetica;
  font-size: 12.9px;
  font-weight: 700;
  line-height: 15.4px;
  position: relative;
}

.case .text-wrapper-54 {
  color: #868686;
  letter-spacing: -.16px;
  width: 472.38px;
  font-family: Public Sans, Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  line-height: 14.9px;
  position: relative;
}

.case .overlap-8 {
  background-color: #e3edf7;
  width: 805px;
  height: 153px;
  position: absolute;
  top: 1534px;
  left: 0;
}

.case .text-wrapper-55 {
  color: #222;
  letter-spacing: -.16px;
  width: 477px;
  height: 30px;
  font-family: Public Sans, Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  line-height: 14.9px;
  position: absolute;
  top: 72px;
  left: 45px;
}

.case .buttons-6 {
  background-color: #0d0d0d;
  border-radius: 2.24px;
  justify-content: center;
  align-items: center;
  gap: 5.59px;
  width: 122px;
  padding: 8.94px 13.42px;
  display: flex;
  position: absolute;
  top: 72px;
  left: 638px;
}

.case .text-wrapper-56 {
  color: #1b1b1b;
  letter-spacing: -.22px;
  white-space: nowrap;
  height: 19px;
  font-family: Public Sans, Helvetica;
  font-size: 15.7px;
  font-weight: 700;
  line-height: 18.8px;
  position: absolute;
  top: 44px;
  left: 45px;
}

.case .overlap-9 {
  width: 805px;
  height: 277px;
  position: absolute;
  top: 790px;
  left: 0;
}

.case .section-BG-image-2 {
  width: 805px;
  height: 232px;
  position: absolute;
  top: 0;
  left: 0;
}

.case .frame-77 {
  align-items: flex-start;
  gap: 13.42px;
  display: inline-flex;
  position: absolute;
  top: 95px;
  left: 45px;
}

.case .frame-78 {
  background-color: #fff;
  border-radius: 4.47px;
  flex-direction: column;
  align-items: flex-start;
  gap: 17.89px;
  width: 168.83px;
  height: 181.68px;
  padding: 17.89px 22.36px 26.83px;
  display: flex;
  position: relative;
  box-shadow: 0 2.24px 13.42px #3737371f;
}

.case .img-4 {
  width: 23.48px;
  height: 23.48px;
  position: relative;
}

.case .frame-79 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8.94px;
  display: inline-flex;
  position: relative;
}

.case .text-wrapper-57 {
  color: #0d0d0d;
  letter-spacing: -.15px;
  width: 124.1px;
  margin-top: -.56px;
  font-family: Public Sans, Helvetica;
  font-size: 12.9px;
  font-weight: 700;
  line-height: 15.4px;
  position: relative;
}

.case .text-wrapper-58 {
  color: #858585;
  letter-spacing: -.04px;
  width: 124.1px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 12.9px;
  position: relative;
}

.case .text-wrapper-59 {
  color: #fff;
  letter-spacing: -.81px;
  text-align: center;
  white-space: nowrap;
  height: 32px;
  font-family: Public Sans, Helvetica;
  font-size: 26.8px;
  font-weight: 800;
  line-height: 32.2px;
  position: absolute;
  top: 36px;
  left: 45px;
}

.case .frame-80 {
  background-color: #0d0d0d;
  width: 805px;
  height: 224px;
  position: absolute;
  top: 1913px;
  left: 0;
  overflow: hidden;
}

.case .overlap-group-3 {
  background-image: url("mask-group-7.549fed9e.png");
  background-position: 50%;
  background-size: cover;
  width: 100px;
  height: 28px;
  position: absolute;
  top: -5658px;
  left: 907px;
}

.case .mail-outline-black-2 {
  width: 13.42px;
  height: 13.42px;
  margin-top: -5750.11px;
  margin-right: -97.39px;
  position: relative;
}

.case .place-black-dp {
  width: 13.42px;
  height: 13.42px;
  margin-top: -5774.71px;
  margin-right: -93.7px;
  position: relative;
}

.case .t-hub-gachibowli-2 {
  color: #fff;
  letter-spacing: 0;
  width: 110.69px;
  margin-top: -.56px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 13.4px;
  position: relative;
}

.case .frame-81 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8.94px;
  width: 716px;
  display: flex;
  position: absolute;
  top: 179px;
  left: 45px;
}

.case .vector-4 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -5870.04px;
  margin-right: -795.28px;
  position: relative;
}

.case .frame-82 {
  align-items: flex-start;
  gap: 74.35px;
  display: inline-flex;
  position: absolute;
  top: 365px;
  left: 87px;
}

.case .text-wrapper-60 {
  color: #606060;
  letter-spacing: -.16px;
  text-align: center;
  width: 447px;
  height: 30px;
  font-family: Public Sans, Helvetica;
  font-size: 10.6px;
  font-weight: 400;
  line-height: 14.9px;
  position: absolute;
  top: 1171px;
  left: 179px;
}

.case .text-wrapper-61 {
  color: #000;
  letter-spacing: -.81px;
  text-align: center;
  width: 276px;
  height: 32px;
  font-family: Public Sans, Helvetica;
  font-size: 26.8px;
  font-weight: 800;
  line-height: 32.2px;
  position: absolute;
  top: 1130px;
  left: 264px;
}

.case .CT-as-3 {
  background-color: #0003;
  background-image: url("ctas-2.de88af4c.png");
  background-position: 50%;
  background-size: cover;
  width: 805px;
  height: 226px;
  position: absolute;
  top: 1687px;
  left: 0;
}

.case .text-wrapper-62 {
  color: var(--neutrals-0);
  letter-spacing: -.81px;
  white-space: nowrap;
  height: 32px;
  font-family: Manrope, Helvetica;
  font-size: 26.8px;
  font-weight: 800;
  line-height: 32.2px;
  position: absolute;
  top: 57px;
  left: 245px;
}

.case .text-wrapper-63 {
  color: #fff;
  letter-spacing: -.11px;
  white-space: nowrap;
  height: 13px;
  font-family: Public Sans, Helvetica;
  font-size: 10.6px;
  font-weight: 600;
  line-height: 12.7px;
  position: absolute;
  top: 98px;
  left: 343px;
}

.case .buttons-7 {
  background-color: var(--white);
  border: .56px solid #cfcfcf;
  border-radius: 2.24px;
  justify-content: center;
  align-items: center;
  gap: 5.59px;
  padding: 8.94px 13.42px;
  display: inline-flex;
  position: absolute;
  top: 134px;
  left: 352px;
  overflow: hidden;
}

.case .meet-the-chefs {
  flex-direction: column;
  align-items: flex-end;
  gap: 5.59px;
  width: 805px;
  padding: 22.36px 0 31.31px;
  display: flex;
  position: absolute;
  top: 1215px;
  left: 0;
}

.case .list-wrapper {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8.94px;
  width: 806.12px;
  margin-left: -1.12px;
  display: flex;
  position: relative;
}

.case .list {
  align-items: flex-start;
  gap: 17.89px;
  height: 233.11px;
  margin-left: -5.59px;
  margin-right: -5.59px;
  display: inline-flex;
  position: relative;
}

.case .section-image-4 {
  object-fit: cover;
  align-self: stretch;
  width: 174.42px;
  position: relative;
}

.case .section-image-5 {
  object-fit: cover;
  align-self: stretch;
  width: 181.12px;
  position: relative;
}

.case .section-image-6 {
  object-fit: cover;
  flex: none;
  align-self: stretch;
  position: relative;
}

.case .contact-us {
  background-color: #fff;
  border: 8px solid #e6e6e6;
  border-radius: 8px;
  width: 814px;
  height: 467px;
  position: absolute;
  top: 13295px;
  left: 113px;
  overflow: hidden;
}

.case .overlap-10 {
  background-image: url("section-01-bg-main-1.8b634a71.svg");
  background-size: 100% 100%;
  width: 798px;
  height: 451px;
  position: absolute;
  top: 0;
  left: 0;
}

.case .frame-83 {
  background-color: #fff;
  border-radius: 4.43px;
  align-items: flex-start;
  gap: 5.54px;
  width: 320px;
  padding: 31.03px 35.47px;
  display: flex;
  position: absolute;
  top: 90px;
  left: 433px;
  overflow: hidden;
  box-shadow: 0 2.22px 8.87px 1.11px #00000026;
}

.case .frame-84 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 26.6px;
  display: flex;
  position: relative;
}

.case .frame-85 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 17.73px;
  width: 100%;
  display: flex;
  position: relative;
}

.case .frame-86 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 13.3px;
  width: 100%;
  display: flex;
  position: relative;
}

.case .frame-87 {
  background-color: #fff;
  border: .55px solid #cfcfcf;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5.54px;
  width: 100%;
  padding: 6.65px 8.87px;
  display: flex;
  position: relative;
}

.case .div-3 {
  color: #0000;
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.55px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 12.8px;
  position: relative;
}

.case .text-wrapper-64 {
  color: #00000080;
}

.case .text-wrapper-65 {
  color: red;
}

.case .text-wrapper-66 {
  color: #00000080;
  letter-spacing: -.04px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.55px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 12.8px;
  position: relative;
}

.case .frame-88 {
  background-color: #fff;
  border: .55px solid #cfcfcf;
  align-self: stretch;
  align-items: flex-start;
  gap: 5.54px;
  width: 100%;
  height: 93.1px;
  padding: 6.65px 8.87px;
  display: flex;
  position: relative;
}

.case .buttons-8 {
  background-color: #0d0d0d;
  border-radius: 2.22px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 5.54px;
  width: 100%;
  padding: 8.87px 13.3px;
  display: flex;
  position: relative;
}

.case .text-wrapper-67 {
  color: #fff;
  letter-spacing: -.18px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.55px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 700;
  line-height: 12.4px;
  position: relative;
}

.case .frame-89 {
  flex-direction: column;
  align-items: flex-start;
  gap: 31.03px;
  width: 288px;
  display: flex;
  position: absolute;
  top: 90px;
  left: 44px;
}

.case .text-wrapper-68 {
  color: #0d0d0d;
  letter-spacing: -.32px;
  align-self: stretch;
  margin-top: -.55px;
  font-family: Public Sans, Helvetica;
  font-size: 31.6px;
  font-weight: 700;
  line-height: 37.9px;
  position: relative;
}

.case .text-wrapper-69 {
  color: #606060;
  letter-spacing: -.15px;
  width: 288.17px;
  font-family: Public Sans, Helvetica;
  font-size: 12.7px;
  font-weight: 400;
  line-height: 17.8px;
  position: relative;
}

.case .frame-90 {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.54px;
  padding: 8.87px 44.33px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.case .frame-91 {
  flex: none;
  align-items: center;
  gap: 311.44px;
  width: 709.33px;
  display: flex;
  position: relative;
}

.case .frame-92 {
  flex: none;
  align-items: center;
  gap: 17.73px;
  display: inline-flex;
  position: relative;
}

.case .frame-93 {
  width: 71.49px;
  height: 20.5px;
  position: relative;
}

.case .image-2 {
  object-fit: cover;
  width: 71px;
  height: 19px;
  position: absolute;
  top: 1px;
  left: 0;
}

.case .frame-94 {
  align-items: center;
  gap: 19.95px;
  width: 149.07px;
  display: flex;
  position: relative;
}

.case .component-2 {
  border-radius: 4.43px !important;
  flex: none !important;
  gap: 5.54px !important;
  height: 19.4px !important;
  padding: 4.43px 6.65px !important;
}

.case .component-3 {
  margin-top: -.29px !important;
  font-size: 8.9px !important;
}

.case .component-4 {
  width: 8.87px !important;
  height: 8.87px !important;
}

.case .frame-95 {
  background-color: #0d0d0d;
  width: 798px;
  height: 222px;
  position: absolute;
  top: 453px;
  left: 0;
  overflow: hidden;
}

.case .overlap-group-4 {
  background-image: url("section-01-image-01-1.bc340a45.png");
  background-position: 50%;
  background-size: cover;
  width: 99px;
  height: 28px;
  position: absolute;
  top: -6176px;
  left: 906px;
}

.case .mask-group-2 {
  width: 99px;
  height: 28px;
  position: absolute;
  top: 0;
  left: 0;
}

.case .company-links-3 {
  width: 69px;
  height: 112px;
  position: absolute;
  top: 33px;
  left: 406px;
}

.case .text-wrapper-70 {
  color: #fff;
  letter-spacing: 0;
  font-family: Public Sans, Helvetica;
  font-size: 10px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.case .text-wrapper-71 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 13.3px;
  position: absolute;
  top: 24px;
  left: 0;
}

.case .text-wrapper-72 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 13.3px;
  position: absolute;
  top: 49px;
  left: 0;
}

.case .resources-links-2 {
  width: 71px;
  height: 112px;
  position: absolute;
  top: 33px;
  left: 488px;
}

.case .text-wrapper-73 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 13.3px;
  position: absolute;
  top: 73px;
  left: 0;
}

.case .frame-96 {
  flex-direction: column;
  align-items: flex-start;
  gap: 13.3px;
  height: 112px;
  display: inline-flex;
  position: absolute;
  top: 33px;
  left: 625px;
}

.case .text-wrapper-74 {
  color: #fff;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.55px;
  font-family: Public Sans, Helvetica;
  font-size: 10px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.case .frame-97 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 11.08px;
  display: inline-flex;
  position: relative;
}

.case .frame-98 {
  flex: none;
  align-items: center;
  gap: 5.54px;
  display: inline-flex;
  position: relative;
}

.case .mail-outline-black-3 {
  width: 13.3px;
  height: 13.3px;
  margin-top: -6267.38px;
  margin-right: -102.91px;
  position: relative;
}

.case .text-wrapper-75 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.4px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 13.3px;
  position: relative;
}

.case .frame-99 {
  flex: none;
  align-items: flex-start;
  gap: 5.54px;
  display: inline-flex;
  position: relative;
}

.case .place-black-2 {
  width: 13.3px;
  height: 13.3px;
  margin-top: -6291.77px;
  margin-right: -99.19px;
  position: relative;
}

.case .t-hub-gachibowli-3 {
  color: #fff;
  letter-spacing: 0;
  width: 109.72px;
  margin-top: -.55px;
  font-family: Public Sans, Helvetica;
  font-size: 8.9px;
  font-weight: 400;
  line-height: 13.3px;
  position: relative;
}

.case .text-wrapper-76 {
  color: #fff;
  letter-spacing: 0;
  width: 170px;
  font-family: Public Sans, Helvetica;
  font-size: 7.8px;
  font-weight: 400;
  line-height: 11.6px;
  position: absolute;
  top: 74px;
  left: 66px;
}

.case .frame-100 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8.87px;
  width: 709px;
  display: flex;
  position: absolute;
  top: 177px;
  left: 44px;
}

.case .vector-5 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -6386.17px;
  margin-right: -794.67px;
  position: relative;
}

.case .text-wrapper-77 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Public Sans, Helvetica;
  font-size: 7.8px;
  font-weight: 400;
  line-height: 11.6px;
  position: relative;
}

.case .frame-101 {
  flex: none;
  align-items: center;
  gap: 13.3px;
  display: inline-flex;
  position: relative;
}

.case .frame-102 {
  background-color: #50d4f2;
  width: 1920px;
  height: 1980px;
  position: absolute;
  top: 13850px;
  left: 0;
  overflow: hidden;
}

.case .mobile-home-page {
  background-color: #fff;
  border: 8px solid #1269b752;
  border-radius: 13.89px;
  width: 370px;
  height: 6820px;
  position: absolute;
  top: -173px;
  left: -737px;
  overflow: hidden;
  transform: rotate(15deg);
}

.case .overlap-11 {
  width: 551px;
  height: 1818px;
  position: absolute;
  top: 934px;
  left: 0;
}

.case .rectangle {
  background: linear-gradient(#fffbdb 0%, #fff 100%);
  width: 354px;
  height: 662px;
  position: absolute;
  top: 246px;
  left: 0;
}

.case .metrics {
  background-color: #fffbdb;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 354px;
  padding: 39.39px 15.75px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.case .frame-103 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 47.26px;
  width: 100%;
  display: flex;
  position: relative;
}

.case .frame-104 {
  flex: none;
  align-items: flex-start;
  gap: 15.75px;
  display: inline-flex;
  position: relative;
}

.case .frame-105 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7.88px;
  width: 153.6px;
  display: flex;
  position: relative;
}

.case .text-wrapper-78 {
  color: #191919;
  letter-spacing: -.26px;
  text-align: center;
  width: 153.6px;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 25.6px;
  font-weight: 700;
  line-height: 30.7px;
  position: relative;
}

.case .text-wrapper-79 {
  color: #191919;
  letter-spacing: -.24px;
  text-align: center;
  width: 153.6px;
  font-family: Public Sans, Helvetica;
  font-size: 15.8px;
  font-weight: 400;
  line-height: 22.1px;
  position: relative;
}

.case .frame-106 {
  flex-direction: column;
  align-items: center;
  gap: 7.88px;
  width: 153.6px;
  display: flex;
  position: relative;
}

.case .frame-107 {
  flex-direction: column;
  align-items: flex-start;
  gap: 7.88px;
  width: 153.6px;
  display: flex;
  position: relative;
}

.case .frame-108 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.82px;
  display: inline-flex;
  position: absolute;
  top: 286px;
  left: 16px;
}

.case .frame-109 {
  flex-direction: column;
  flex: none;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 23.63px;
  display: inline-flex;
  position: relative;
}

.case .frame-110 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 13.79px;
  display: inline-flex;
  position: relative;
}

.case .frame-111 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 23.63px;
  display: inline-flex;
  position: relative;
}

.case .frame-112 {
  background-color: #0d0d0d;
  border-radius: 31.51px;
  flex: none;
  align-items: flex-start;
  gap: 9.85px;
  padding: 3.94px 7.88px;
  display: inline-flex;
  position: relative;
}

.case .text-wrapper-80 {
  color: #fff;
  letter-spacing: -.2px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 9.8px;
  font-weight: 700;
  line-height: 13.8px;
  position: relative;
}

.case .text-wrapper-81 {
  color: #0d0d0d;
  letter-spacing: -.23px;
  width: 322.96px;
  font-family: Public Sans, Helvetica;
  font-size: 22.6px;
  font-weight: 800;
  line-height: 27.2px;
  position: relative;
}

.case .frame-113 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 31.51px;
  display: inline-flex;
  position: relative;
}

.case .frame-114 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 31.51px;
  width: 100%;
  display: flex;
  position: relative;
}

.case .text-wrapper-82 {
  color: #606060;
  letter-spacing: -.07px;
  width: 322.96px;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .button {
  all: unset;
  box-sizing: border-box;
  border: .98px solid #0d0d0d;
  border-radius: 31.51px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  padding: 13.79px 23.63px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.case .text-wrapper-83 {
  color: #0d0d0d;
  letter-spacing: -.28px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 700;
  line-height: 16.5px;
  position: relative;
}

.case .section-main-4 {
  width: 206px;
  height: 511px;
  position: absolute;
  top: 460px;
  left: 282px;
  transform: rotate(-15deg);
}

.case .section-BG-main {
  width: 30px;
  height: 296px;
  position: absolute;
  top: 816px;
  left: 346px;
  transform: rotate(-15deg);
}

.case .frame-115 {
  flex-direction: column;
  align-items: flex-start;
  gap: 39.39px;
  width: 323px;
  display: flex;
  position: absolute;
  top: 967px;
  left: 16px;
}

.case .type-9 {
  color: #fff;
  letter-spacing: -.2px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 19.7px;
  font-weight: 600;
  line-height: 25.6px;
  position: relative;
}

.case .frame-116 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 15.75px;
  width: 100%;
  display: flex;
  position: relative;
}

.case .frame-117 {
  background-color: #fff;
  border-radius: 7.88px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 19.69px;
  width: 100%;
  padding: 31.51px 31.51px 47.26px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 3.94px 23.63px #3737371f;
}

.case .section-icon-2 {
  width: 47.26px;
  height: 47.26px;
  margin-top: -8187.24px;
  margin-left: -835.5px;
  position: relative;
  transform: rotate(-15deg);
}

.case .frame-118 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7.88px;
  width: 100%;
  display: flex;
  position: relative;
}

.case .text-wrapper-84 {
  color: #0d0d0d;
  letter-spacing: -.32px;
  align-self: stretch;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 15.8px;
  font-weight: 800;
  line-height: 18.9px;
  position: relative;
}

.case .text-wrapper-85 {
  color: #595959;
  letter-spacing: -.07px;
  width: 259.95px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .frame-119 {
  background-color: #fff;
  border-radius: 7.88px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 19.69px;
  width: 100%;
  padding: 31.51px 31.51px 47.26px;
  display: flex;
  position: relative;
  box-shadow: 0 3.94px 23.63px #3737371f;
}

.case .section-icon-3 {
  width: 47.26px;
  height: 47.26px;
  margin-top: -8451.6px;
  margin-left: -835.5px;
  position: relative;
  transform: rotate(-15deg);
}

.case .frame-120 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 11.82px;
  width: 100%;
  display: flex;
  position: relative;
}

.case .text-wrapper-86 {
  color: #595959;
  letter-spacing: -.07px;
  width: 244.19px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .section-icon-4 {
  width: 47.26px;
  height: 47.26px;
  margin-top: -8719.9px;
  margin-left: -835.5px;
  position: relative;
  transform: rotate(-15deg);
}

.case .text-wrapper-87 {
  color: #595959;
  letter-spacing: -.07px;
  width: 236.31px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .div-4 {
  background-color: #fff;
  width: 354px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
}

.case .navbar-company-logo {
  width: 87px;
  height: 24px;
  position: absolute;
  top: -6205px;
  left: -811px;
  transform: rotate(-15deg);
}

.case .frame-121 {
  width: 68px;
  height: 50px;
  position: absolute;
  top: 11px;
  left: 295px;
  transform: rotate(-15deg);
}

.case .frame-122 {
  background: linear-gradient(#1269b7 0%, #af62e5 100%);
  border-radius: 3.94px;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  padding: 7.88px 11.82px;
  display: inline-flex;
  position: absolute;
  top: 10px;
  left: 193px;
}

.case .text-wrapper-88 {
  color: #fff;
  letter-spacing: -.28px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 700;
  line-height: 19.3px;
  position: relative;
}

.case .group-wrapper {
  width: 354px;
  height: 295px;
  position: absolute;
  top: 393px;
  left: 0;
  overflow: hidden;
}

.case .group-2 {
  width: 1063px;
  height: 295px;
  position: relative;
}

.case .section-image-wrapper {
  background-image: url("section-01-image-01.a85eae4d.png");
  background-position: 50%;
  background-size: cover;
  width: 399px;
  height: 377px;
  position: absolute;
  top: -133px;
  left: 0;
}

.case .section-image-7 {
  object-fit: cover;
  width: 76px;
  height: 285px;
  position: absolute;
  top: 92px;
  left: 322px;
}

.case .section-image-8 {
  width: 354px;
  height: 295px;
  position: absolute;
  top: -6720px;
  left: 1669px;
}

.case .hero-text {
  flex-direction: column;
  align-items: center;
  display: inline-flex;
  position: absolute;
  top: 154px;
  left: 0;
}

.case .frame-123 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 3.94px;
  width: 100%;
  padding: 3.94px 0;
  display: flex;
  position: relative;
}

.case .text-wrapper-89 {
  color: #0d0d0d;
  letter-spacing: -.69px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 27.6px;
  font-weight: 700;
  line-height: 35.8px;
  position: relative;
}

.case .component-6 {
  width: 86.65px !important;
  height: 33.48px !important;
  position: relative !important;
}

.case .component-6-instance {
  width: 132px !important;
  height: 131px !important;
  top: -98px !important;
}

.case .component-7 {
  letter-spacing: -.69px !important;
  height: 33px !important;
  font-size: 27.6px !important;
  line-height: 33.1px !important;
}

.case .component-8 {
  letter-spacing: -.69px !important;
  height: 33px !important;
  font-size: 27.6px !important;
  line-height: 33.1px !important;
  top: 49px !important;
}

.case .component-9 {
  letter-spacing: -.69px !important;
  height: 33px !important;
  font-size: 27.6px !important;
  line-height: 33.1px !important;
  top: 98px !important;
}

.case .text-wrapper-90 {
  color: #0d0d0d;
  letter-spacing: -.69px;
  text-align: center;
  width: 354.47px;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 27.6px;
  font-weight: 700;
  line-height: 35.8px;
  position: relative;
}

.case .frame-124 {
  justify-content: center;
  align-items: center;
  gap: 15.75px;
  display: inline-flex;
  position: absolute;
  top: 290px;
  left: 58px;
  overflow: hidden;
}

.case .buttons-9 {
  background: linear-gradient(#1269b7 0%, #af62e5 100%);
  border-radius: 3.94px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  padding: 11.82px 15.75px;
  display: inline-flex;
  position: relative;
}

.case .text-wrapper-91 {
  color: var(--white);
  letter-spacing: -.28px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 700;
  line-height: 19.3px;
  position: relative;
}

.case .buttons-10 {
  all: unset;
  background-color: var(--white);
  box-sizing: border-box;
  border: .98px solid #cfcfcf;
  border-radius: 3.94px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  padding: 11.82px 15.75px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.case .text-wrapper-92 {
  color: #3d3d3d;
  letter-spacing: -.07px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.9px;
  position: relative;
}

.case .headline {
  color: #0d0d0d;
  letter-spacing: -.2px;
  text-align: center;
  width: 307px;
  height: 130px;
  font-family: Public Sans, Helvetica;
  font-size: 19.7px;
  font-weight: 600;
  line-height: 25.6px;
  position: absolute;
  top: 747px;
  left: 24px;
}

.case .overlap-12 {
  width: 483px;
  height: 466px;
  position: absolute;
  top: -3514px;
  left: -112px;
}

.case .headline-2 {
  width: 309px;
  height: 105px;
  position: absolute;
  top: 177px;
  left: 91px;
  transform: rotate(-15deg);
}

.case .section-main-5 {
  width: 400px;
  height: 375px;
  position: absolute;
  top: 45px;
  left: 42px;
  transform: rotate(-15deg);
}

.case .frame-125 {
  flex-direction: column;
  align-items: center;
  gap: 13.79px;
  height: 233px;
  padding: 0 0 36.43px;
  display: inline-flex;
  position: absolute;
  top: 3333px;
  left: 16px;
}

.case .frame-126 {
  background-color: #9c9fa11f;
  border-radius: 49.23px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  padding: 3.94px 7.88px;
  display: inline-flex;
  position: relative;
}

.case .type-10 {
  color: #0d0d0d;
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 9.8px;
  font-weight: 700;
  line-height: 13.8px;
  position: relative;
}

.case .frame-127 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 11.82px;
  margin-bottom: -35.51px;
  display: inline-flex;
  position: relative;
}

.case .text-wrapper-93 {
  color: #0d0d0d;
  letter-spacing: -.23px;
  text-align: center;
  width: 322.96px;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 22.6px;
  font-weight: 800;
  line-height: 27.2px;
  position: relative;
}

.case .create-immersive {
  color: #606060;
  letter-spacing: -.24px;
  text-align: center;
  width: 322.96px;
  height: 130.96px;
  font-family: Public Sans, Helvetica;
  font-size: 15.8px;
  font-weight: 400;
  line-height: 22.1px;
  position: relative;
}

.case .frame-128 {
  flex-direction: column;
  align-items: flex-start;
  gap: 19.69px;
  display: inline-flex;
  position: absolute;
  top: 3606px;
  left: 16px;
}

.case .frame-129 {
  width: 322.96px;
  height: 551.4px;
  position: relative;
  overflow: hidden;
}

.case .section-image-9 {
  width: 324px;
  height: 246px;
  position: absolute;
  top: -9559px;
  left: -742px;
  transform: rotate(-15deg);
}

.case .overlap-group-5 {
  background-color: #0d0d0d;
  width: 324px;
  height: 308px;
  position: absolute;
  top: 0;
  left: 0;
}

.case .frame-130 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15.75px;
  display: inline-flex;
  position: relative;
  top: 39px;
  left: 24px;
}

.case .frame-131 {
  background: linear-gradient(#1269b7 0%, #8064d7 47.99%, #af62e5 68.75%);
  border-radius: 49.23px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  padding: 3.94px 11.82px;
  display: inline-flex;
  position: relative;
}

.case .type-11 {
  color: #fff;
  letter-spacing: -.21px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 600;
  line-height: 16.5px;
  position: relative;
}

.case .frame-132 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 27.57px;
  display: inline-flex;
  position: relative;
}

.case .frame-133 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 11.82px;
  display: inline-flex;
  position: relative;
}

.case .type-12 {
  color: #fff;
  letter-spacing: -.18px;
  text-align: center;
  width: 275.7px;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 17.7px;
  font-weight: 600;
  line-height: 21.3px;
  position: relative;
}

.case .type-13 {
  color: #898989;
  letter-spacing: -.07px;
  text-align: center;
  width: 275.7px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .frame-134 {
  border: .98px solid #fff;
  border-radius: 31.51px;
  flex: none;
  align-items: center;
  gap: 3.94px;
  padding: 11.82px 23.63px;
  display: inline-flex;
  position: relative;
}

.case .text-wrapper-94 {
  color: #fff;
  letter-spacing: -.28px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 700;
  line-height: 16.5px;
  position: relative;
}

.case .arrow-forward-2 {
  width: 11.82px;
  height: 11.82px;
  margin-top: -10051.9px;
  margin-left: -864.26px;
  position: relative;
  transform: rotate(-15deg);
}

.case .frame-135 {
  background-color: #fff;
  width: 322.96px;
  height: 551.4px;
  position: relative;
  overflow: hidden;
}

.case .section-image-10 {
  width: 323px;
  height: 551px;
  position: absolute;
  top: -10432px;
  left: -782px;
  transform: rotate(-15deg);
}

.case .frame-136 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 23.63px;
  display: inline-flex;
  position: absolute;
  top: 280px;
  left: 24px;
}

.case .frame-137 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 31.51px;
  display: inline-flex;
  position: relative;
}

.case .text-wrapper-95 {
  color: #fff;
  letter-spacing: -.07px;
  text-align: center;
  width: 275.7px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .arrow-forward-3 {
  width: 11.82px;
  height: 11.82px;
  margin-top: -10875.1px;
  margin-left: -864.26px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-image-11 {
  width: 354px;
  height: 415px;
  position: absolute;
  top: -1667px;
  left: 445px;
  transform: rotate(-15deg);
}

.case .frame-138 {
  flex-direction: column;
  align-items: flex-start;
  gap: 15.75px;
  display: inline-flex;
  position: absolute;
  top: 4850px;
  left: 16px;
}

.case .frame-139 {
  background-color: #ffffff29;
  border-radius: 49.23px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  padding: 3.94px 7.88px;
  display: inline-flex;
  position: relative;
}

.case .frame-142 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15.75px;
  display: inline-flex;
  position: relative;
}

.case .text-wrapper-96 {
  color: #fff;
  letter-spacing: -.18px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 17.7px;
  font-weight: 600;
  line-height: 21.3px;
  position: relative;
}

.case .text-wrapper-97 {
  color: #fff;
  letter-spacing: -.07px;
  width: 322.96px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .buttons-11 {
  all: unset;
  box-sizing: border-box;
  border: .98px solid #fff;
  border-radius: 3.94px;
  flex: none;
  align-items: flex-start;
  gap: 9.85px;
  padding: 11.82px 15.75px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.case .text-wrapper-98 {
  color: var(--white);
  letter-spacing: -.07px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.9px;
  position: relative;
}

.case .section-main-6 {
  width: 323px;
  height: 299px;
  position: absolute;
  top: -1227px;
  left: 564px;
  transform: rotate(-15deg);
}

.case .text-container-3 {
  border-radius: 11.82px;
  width: 354px;
  height: 428px;
  position: absolute;
  top: 5525px;
  left: 0;
  overflow: hidden;
}

.case .text-wrapper-99 {
  color: var(--dark);
  letter-spacing: -.2px;
  text-align: center;
  width: 234px;
  font-family: Public Sans, Helvetica;
  font-size: 19.7px;
  font-weight: 600;
  line-height: 25.6px;
  position: absolute;
  top: 110px;
  left: 60px;
}

.case .section-quotes {
  width: 29px;
  height: 24px;
  position: absolute;
  top: -11725px;
  left: -660px;
  transform: rotate(-15deg);
}

.case .frame-143 {
  align-items: center;
  gap: 11.82px;
  display: inline-flex;
  position: absolute;
  top: 312px;
  left: 66px;
}

.case .section-image-12 {
  width: 47.26px;
  height: 47.26px;
  margin-top: -12048.3px;
  margin-left: -892.8px;
  position: relative;
  transform: rotate(-15deg);
}

.case .frame-144 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 3.94px;
  display: inline-flex;
  position: relative;
}

.case .text-wrapper-100 {
  color: var(--dark);
  letter-spacing: -.32px;
  width: 167.39px;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 15.8px;
  font-weight: 800;
  line-height: 18.9px;
  position: relative;
}

.case .text-wrapper-101 {
  color: var(--dark);
  letter-spacing: -.07px;
  width: 167.39px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .frame-145 {
  align-items: flex-start;
  gap: 3.94px;
  display: inline-flex;
  position: absolute;
  top: 418px;
  left: 161px;
}

.case .ellipse-4 {
  background-color: #d9d9d9;
  border-radius: 3.94px;
  width: 7.88px;
  height: 7.88px;
  position: relative;
}

.case .ellipse-5 {
  background-color: #1269b7;
  border-radius: 3.94px;
  width: 7.88px;
  height: 7.88px;
  position: relative;
}

.case .section-main-7 {
  width: 354px;
  height: 209px;
  position: absolute;
  top: -465px;
  left: 740px;
  transform: rotate(-15deg);
}

.case .frame-146 {
  background-color: #0d0d0d;
  width: 354px;
  height: 603px;
  position: absolute;
  top: 6201px;
  left: 0;
  overflow: hidden;
}

.case .overlap-13 {
  width: 95px;
  height: 48px;
  position: absolute;
  top: -12399px;
  left: -808px;
}

.case .image-3 {
  object-fit: cover;
  width: 91px;
  height: 26px;
  position: absolute;
  top: 11px;
  left: 2px;
  transform: rotate(-15deg);
}

.case .img-5 {
  width: 91px;
  height: 26px;
  position: absolute;
  top: 11px;
  left: 2px;
  transform: rotate(-15deg);
}

.case .text-wrapper-102 {
  color: #fffc;
  letter-spacing: -.07px;
  width: 316px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: absolute;
  top: 76px;
  left: 16px;
}

.case .company-links-4 {
  width: 98px;
  height: 152px;
  position: absolute;
  top: 330px;
  left: 16px;
  overflow: hidden;
}

.case .text-wrapper-103 {
  color: #fff;
  letter-spacing: -.28px;
  white-space: nowrap;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 700;
  line-height: 16.5px;
  position: absolute;
  top: -1px;
  left: 0;
}

.case .text-wrapper-104 {
  color: #fffc;
  letter-spacing: -.07px;
  white-space: nowrap;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: absolute;
  top: 39px;
  left: 0;
}

.case .text-wrapper-105 {
  color: #fffc;
  letter-spacing: -.07px;
  white-space: nowrap;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: absolute;
  top: 83px;
  left: 0;
}

.case .resources-links-3 {
  width: 127px;
  height: 152px;
  position: absolute;
  top: 330px;
  left: 185px;
  overflow: hidden;
}

.case .text-wrapper-106 {
  color: #fffc;
  letter-spacing: -.07px;
  white-space: nowrap;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: absolute;
  top: 126px;
  left: 0;
}

.case .frame-147 {
  flex-direction: column;
  align-items: flex-start;
  gap: 19.69px;
  height: 131px;
  display: inline-flex;
  position: absolute;
  top: 160px;
  left: 16px;
}

.case .text-wrapper-107 {
  color: #fff;
  letter-spacing: -.28px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 700;
  line-height: 16.5px;
  position: relative;
}

.case .frame-148 {
  flex: none;
  align-items: center;
  gap: 9.85px;
  display: inline-flex;
  position: relative;
}

.case .footer-icon-email {
  width: 23.63px;
  height: 23.63px;
  margin-top: -12605.3px;
  margin-left: -845.24px;
  position: relative;
  transform: rotate(-15deg);
}

.case .text-wrapper-108 {
  color: #fffc;
  letter-spacing: -.07px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .frame-149 {
  flex: none;
  align-items: flex-start;
  gap: 9.85px;
  display: inline-flex;
  position: relative;
}

.case .footer-icon-address {
  width: 23.63px;
  height: 23.63px;
  margin-top: -12644.7px;
  margin-left: -845.24px;
  position: relative;
  transform: rotate(-15deg);
}

.case .t-hub-gachibowli-4 {
  color: #fffc;
  letter-spacing: -.07px;
  width: 194.96px;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .footer-social-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -12442px;
  left: -561px;
  transform: rotate(-15deg);
}

.case .footer-social-icon-2 {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -12455px;
  left: -515px;
  transform: rotate(-15deg);
}

.case .text-wrapper-109 {
  color: #ffffffbf;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Public Sans, Helvetica;
  font-size: 9.8px;
  font-weight: 400;
  line-height: 14.8px;
  position: absolute;
  top: 556px;
  left: 116px;
}

.case .vector-6 {
  width: 323px;
  height: 1px;
  position: absolute;
  top: -11923px;
  left: -681px;
  transform: rotate(-15deg);
}

.case .overlap-14 {
  width: 2519px;
  height: 8327px;
  position: absolute;
  top: -2798px;
  left: -286px;
}

.case .mobile-digited {
  background-color: #fff;
  border: 8px solid #1269b752;
  border-radius: 13.89px;
  width: 370px;
  height: 6153px;
  position: absolute;
  top: -57px;
  left: 790px;
  overflow: hidden;
  transform: rotate(15deg);
}

.case .overlap-15 {
  background-color: #fbfbfb;
  width: 354px;
  height: 1586px;
  position: absolute;
  top: 1059px;
  left: 0;
}

.case .frame-150 {
  flex-direction: column;
  align-items: center;
  gap: 13.79px;
  padding: 0 0 36.43px;
  display: inline-flex;
  position: absolute;
  top: 39px;
  left: 16px;
}

.case .type-14 {
  color: #0d0d0d;
  letter-spacing: -.23px;
  text-align: center;
  width: 322.96px;
  font-family: Public Sans, Helvetica;
  font-size: 22.6px;
  font-weight: 800;
  line-height: 27.2px;
  position: relative;
}

.case .frame-151 {
  flex-direction: column;
  align-items: center;
  gap: 63.02px;
  display: inline-flex;
  position: absolute;
  top: 214px;
  left: 16px;
}

.case .frame-152 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 11.82px;
  display: inline-flex;
  position: relative;
}

.case .text-wrapper-110 {
  color: #0d0d0d;
  letter-spacing: -.18px;
  width: 322.96px;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 17.7px;
  font-weight: 600;
  line-height: 21.3px;
  position: relative;
}

.case .text-wrapper-111 {
  color: #595959;
  letter-spacing: -.07px;
  width: 322.96px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .section-image-13 {
  width: 322.96px;
  height: 215.31px;
  margin-top: -4474.32px;
  margin-left: -1203.84px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-image-14 {
  width: 322.96px;
  height: 215.31px;
  margin-top: -4926.38px;
  margin-left: -1209.28px;
  position: relative;
  transform: rotate(-15deg);
}

.case .text-wrapper-112 {
  color: #0d0d0d;
  letter-spacing: -.18px;
  width: 303.27px;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 17.7px;
  font-weight: 600;
  line-height: 21.3px;
  position: relative;
}

.case .frame-153 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 27.57px;
  display: inline-flex;
  position: relative;
}

.case .frame-154 {
  flex: none;
  align-items: flex-start;
  gap: 27.57px;
  display: inline-flex;
  position: relative;
}

.case .image-4 {
  object-fit: cover;
  width: 56.45px;
  height: 49.23px;
  margin-top: -5569.4px;
  margin-left: -1260.51px;
  position: relative;
  transform: rotate(-15deg);
}

.case .image-5 {
  object-fit: cover;
  width: 49.23px;
  height: 49.23px;
  margin-top: -5590.21px;
  margin-left: -1179.23px;
  position: relative;
  transform: rotate(-15deg);
}

.case .image-6 {
  object-fit: cover;
  width: 56.45px;
  height: 49.23px;
  margin-top: -5611.02px;
  margin-left: -1105.16px;
  position: relative;
  transform: rotate(-15deg);
}

.case .frame-155 {
  flex: none;
  align-items: flex-end;
  gap: 7.88px;
  display: inline-flex;
  position: relative;
}

.case .text-wrapper-113 {
  color: #af62e5;
  letter-spacing: .59px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Public Sans, Helvetica;
  font-size: 11.8px;
  font-weight: 700;
  line-height: 14.2px;
  position: relative;
}

.case .arrow-forward-4 {
  width: 19.69px;
  height: 15.75px;
  margin-top: -5664.65px;
  margin-left: -1175.47px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-image-15 {
  width: 322.96px;
  height: 215.31px;
  margin-top: -5216.05px;
  margin-left: -1176.83px;
  position: relative;
  transform: rotate(-15deg);
}

.case .overlap-16 {
  width: 354px;
  height: 685px;
  position: absolute;
  top: 55px;
  left: 0;
}

.case .section-main-wrapper {
  background-color: #fff3f0;
  width: 354px;
  height: 685px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.case .section-main-8 {
  width: 473px;
  height: 387px;
  position: absolute;
  top: -3079px;
  left: -1200px;
  transform: rotate(-15deg);
}

.case .frame-156 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 27.57px;
  width: 323px;
  display: flex;
  position: absolute;
  top: 61px;
  left: 16px;
}

.case .frame-157 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 15.75px;
  width: 322.96px;
  display: flex;
  position: relative;
}

.case .text-wrapper-114 {
  color: #0d0d0d;
  letter-spacing: -.07px;
  text-align: center;
  width: 322.96px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .buttons-12 {
  all: unset;
  box-sizing: border-box;
  background-color: #0d0d0d;
  border-radius: 3.94px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  width: 215.64px;
  padding: 11.82px 23.63px;
  display: flex;
  position: relative;
}

.case .frame-158 {
  background: linear-gradient(#1269b7 0%, #af62e5 100%);
  border-radius: 3.94px;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  padding: 7.88px 11.82px;
  display: inline-flex;
  position: relative;
  top: 10px;
  left: 193px;
}

.case .image-7 {
  object-fit: cover;
  width: 87px;
  height: 24px;
  position: absolute;
  top: -3304px;
  left: -1229px;
  transform: rotate(-15deg);
}

.case .frame-159 {
  width: 55px;
  height: 55px;
  position: absolute;
  top: -3389px;
  left: -955px;
  transform: rotate(-15deg);
}

.case .section-main-9 {
  width: 389px;
  height: 173px;
  position: absolute;
  top: 2835px;
  left: 8px;
  transform: rotate(-15deg);
}

.case .text-container-4 {
  border-radius: 11.82px;
  width: 354px;
  height: 428px;
  position: absolute;
  top: 5067px;
  left: 0;
  overflow: hidden;
}

.case .frame-160 {
  width: 29px;
  height: 24px;
  position: absolute;
  top: -8367px;
  left: -1078px;
  transform: rotate(-15deg);
}

.case .frame-161 {
  width: 47.26px;
  height: 47.26px;
  position: relative;
}

.case .ellipse-6 {
  object-fit: cover;
  width: 44px;
  height: 44px;
  position: absolute;
  top: -8688px;
  left: -1309px;
  transform: rotate(-15deg);
}

.case .frame-162 {
  background-color: #0d0d0d;
  width: 354px;
  height: 603px;
  position: absolute;
  top: 5535px;
  left: 0;
  overflow: hidden;
}

.case .overlap-group-6 {
  width: 95px;
  height: 48px;
  position: absolute;
  top: -8832px;
  left: -1226px;
}

.case .mail-outline-black-4 {
  width: 23.63px;
  height: 23.63px;
  margin-top: -9038.28px;
  margin-left: -1263.26px;
  position: relative;
  transform: rotate(-15deg);
}

.case .place-black-3 {
  width: 23.63px;
  height: 23.63px;
  margin-top: -9077.66px;
  margin-left: -1263.26px;
  position: relative;
  transform: rotate(-15deg);
}

.case .vector-7 {
  width: 323px;
  height: 1px;
  position: absolute;
  top: -8356px;
  left: -1099px;
  transform: rotate(-15deg);
}

.case .overlap-17 {
  width: 560px;
  height: 1949px;
  position: absolute;
  top: 3118px;
  left: -103px;
}

.case .rectangle-2 {
  background-color: #f7fafd;
  width: 354px;
  height: 824px;
  position: absolute;
  top: 1124px;
  left: 103px;
}

.case .frame-163 {
  flex-direction: column;
  align-items: flex-start;
  gap: 15.75px;
  display: inline-flex;
  position: absolute;
  top: 1262px;
  left: 118px;
}

.case .frame-164 {
  background-color: #fff;
  border: .5px solid #cfcfcf;
  border-radius: 4.03px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.04px;
  width: 153.6px;
  padding: 16.12px 12.09px;
  display: flex;
  position: relative;
  box-shadow: 0 3.02px 12.09px #7b7b7b0a;
}

.case .frame-165 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 15.75px;
  width: 100%;
  display: flex;
  position: relative;
}

.case .section-finance {
  width: 128.85px;
  height: 91.03px;
  margin-top: -14px;
  position: relative;
  transform: rotate(-15deg);
}

.case .frame-166 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 3.94px;
  width: 100%;
  display: flex;
  position: relative;
}

.case .text-wrapper-115 {
  color: #000;
  letter-spacing: -.18px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.5px;
  font-family: Public Sans, Helvetica;
  font-size: 11.8px;
  font-weight: 600;
  line-height: 14.2px;
  position: relative;
}

.case .text-wrapper-116 {
  color: #858585;
  letter-spacing: -.05px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Public Sans, Helvetica;
  font-size: 9.8px;
  font-weight: 400;
  line-height: 13.8px;
  position: relative;
}

.case .img-6 {
  align-self: stretch;
  width: 100%;
  height: 94.37px;
  margin-top: -15.68px;
  margin-left: -5.95px;
  margin-right: -5.95px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-railways {
  width: 123.6px;
  height: 89.62px;
  margin-top: -13.3px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section {
  align-self: stretch;
  width: 100%;
  height: 54.03px;
  margin-top: -14.99px;
  margin-left: -11.17px;
  margin-right: -.73px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-2 {
  width: 123.6px;
  height: 7.82px;
  margin-top: -11.91px;
  margin-left: -7.67px;
  position: relative;
  transform: rotate(-15deg);
}

.case .frame-167 {
  flex-direction: column;
  align-items: center;
  gap: 13.79px;
  height: 63px;
  padding: 0 0 36.43px;
  display: inline-flex;
  position: absolute;
  top: 1172px;
  left: 118px;
}

.case .type-15 {
  color: #0d0d0d;
  letter-spacing: -.23px;
  text-align: center;
  width: 322.96px;
  margin-bottom: -35.09px;
  font-family: Public Sans, Helvetica;
  font-size: 22.6px;
  font-weight: 800;
  line-height: 27.2px;
  position: relative;
}

.case .frame-168 {
  flex-direction: column;
  align-items: flex-start;
  gap: 23.63px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 118px;
}

.case .element-2 {
  background-color: #fff;
  border-radius: 7.88px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15.75px;
  padding: 23.63px;
  display: inline-flex;
  position: relative;
  box-shadow: 11.82px 6.89px 31.51px #0000000f;
}

.case .section-icon-5 {
  width: 33.77px;
  height: 33.77px;
  margin-top: -3.1px;
  margin-left: -3.1px;
  position: relative;
  transform: rotate(-15deg);
}

.case .frame-169 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 7.88px;
  width: 275.7px;
  display: flex;
  position: relative;
}

.case .type-16 {
  color: #0d0d0d;
  letter-spacing: -.16px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 15.8px;
  font-weight: 500;
  line-height: 18.9px;
  position: relative;
}

.case .text-wrapper-117 {
  color: #858585;
  letter-spacing: -.07px;
  align-self: stretch;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .element-3 {
  background-color: #fff;
  border-radius: 7.88px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 19.69px;
  padding: 23.63px;
  display: inline-flex;
  position: relative;
  box-shadow: 11.82px 6.89px 31.51px #0000000f;
}

.case .frame-170 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 11.82px;
  width: 275.7px;
  display: flex;
  position: relative;
}

.case .text-wrapper-118 {
  color: #858585;
  letter-spacing: -.07px;
  width: 275.7px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .section-main-10 {
  width: 470px;
  height: 408px;
  position: absolute;
  top: 788px;
  left: 45px;
  transform: rotate(-15deg);
}

.case .limitless-knowledge {
  color: #0d0d0d;
  letter-spacing: -.23px;
  text-align: center;
  width: 323px;
  height: 54px;
  font-family: Public Sans, Helvetica;
  font-size: 22.6px;
  font-weight: 800;
  line-height: 27.2px;
  position: absolute;
  top: 3034px;
  left: 16px;
}

.case .frame-171 {
  background-color: #fff;
  width: 354px;
  height: 319px;
  position: absolute;
  top: 740px;
  left: 0;
}

.case .frame-172 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 39.39px;
  display: inline-flex;
  position: absolute;
  top: 36px;
  left: 16px;
}

.case .text-wrapper-119 {
  color: #0d0d0d;
  letter-spacing: -.2px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 19.7px;
  font-weight: 600;
  line-height: 25.6px;
  position: relative;
}

.case .stay-up-to-date-with {
  color: #595959cc;
  letter-spacing: -.07px;
  width: 240.25px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .frame-173 {
  background-color: #fff;
  border: .98px solid #b976e8;
  border-radius: 49.23px;
  align-items: flex-start;
  gap: 9.85px;
  padding: 3.94px 15.75px;
  display: inline-flex;
  position: absolute;
  top: 156px;
  left: 16px;
}

.case .text-wrapper-120 {
  color: #b976e8;
  letter-spacing: -.07px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .frame-174 {
  background-color: #fff;
  border: .98px solid #b976e8;
  border-radius: 49.23px;
  align-items: flex-start;
  gap: 9.85px;
  padding: 3.94px 15.75px;
  display: inline-flex;
  position: absolute;
  top: 156px;
  left: 102px;
}

.case .frame-175 {
  background-color: #fff;
  border: .98px solid #b976e8;
  border-radius: 49.23px;
  align-items: flex-start;
  gap: 9.85px;
  padding: 3.94px 15.75px;
  display: inline-flex;
  position: absolute;
  top: 199px;
  left: 16px;
}

.case .frame-176 {
  background-color: #fff;
  border: .98px solid #b976e8;
  border-radius: 49.23px;
  align-items: flex-start;
  gap: 9.85px;
  padding: 3.94px 15.75px;
  display: inline-flex;
  position: absolute;
  top: 242px;
  left: 16px;
}

.case .frame-177 {
  background-color: #fff;
  border: .98px solid #b976e8;
  border-radius: 49.23px;
  align-items: flex-start;
  gap: 9.85px;
  padding: 3.94px 15.75px;
  display: inline-flex;
  position: absolute;
  top: 199px;
  left: 151px;
}

.case .frame-178 {
  background-color: #fff;
  border: .98px solid #b976e8;
  border-radius: 49.23px;
  align-items: flex-start;
  gap: 9.85px;
  padding: 3.94px 15.75px;
  display: inline-flex;
  position: absolute;
  top: 242px;
  left: 189px;
}

.case .mobile-about-us {
  background-color: #fff;
  border: 8px solid #1269b752;
  border-radius: 13.89px;
  width: 370px;
  height: 5528px;
  position: absolute;
  top: 2715px;
  left: 1439px;
  overflow: hidden;
  transform: rotate(15deg);
}

.case .overlap-18 {
  width: 786px;
  height: 1629px;
  position: absolute;
  top: 1032px;
  left: -316px;
}

.case .section-BG-main-2 {
  width: 674px;
  height: 524px;
  position: absolute;
  top: 492px;
  left: 56px;
  transform: rotate(-15deg);
}

.case .rectangle-3 {
  background-color: #fffbdb;
  width: 354px;
  height: 504px;
  position: absolute;
  top: 0;
  left: 316px;
}

.case .frame-179 {
  flex-direction: column;
  align-items: flex-start;
  gap: 23.63px;
  display: inline-flex;
  position: absolute;
  top: 39px;
  left: 331px;
}

.case .text-wrapper-121 {
  color: #0d0d0d;
  letter-spacing: -.23px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 22.6px;
  font-weight: 800;
  line-height: 27.2px;
  position: relative;
}

.case .text-wrapper-122 {
  color: #0d0d0d;
  letter-spacing: -.16px;
  width: 322.96px;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 15.8px;
  font-weight: 500;
  line-height: 18.9px;
  position: relative;
}

.case .text-wrapper-123 {
  color: #868686;
  letter-spacing: -.07px;
  width: 322.96px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .frame-180 {
  flex-direction: column;
  align-items: flex-start;
  gap: 39.39px;
  width: 323px;
  display: flex;
  position: absolute;
  top: 563px;
  left: 331px;
}

.case .type-17 {
  color: #fff;
  letter-spacing: -.23px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 22.6px;
  font-weight: 800;
  line-height: 27.2px;
  position: relative;
}

.case .section-icon-6 {
  width: 50.65px;
  height: 50.65px;
  margin-top: -4.65px;
  margin-left: -4.65px;
  position: relative;
  transform: rotate(-15deg);
}

.case .text-wrapper-124 {
  color: #0d0d0d;
  letter-spacing: -.18px;
  align-self: stretch;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 17.7px;
  font-weight: 600;
  line-height: 21.3px;
  position: relative;
}

.case .section-icon-data {
  width: 41.36px;
  height: 41.36px;
  margin-top: -8006.43px;
  margin-left: -2517.6px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-icon-7 {
  width: 41.36px;
  height: 41.36px;
  margin-top: -8251.82px;
  margin-left: -2517.6px;
  position: relative;
  transform: rotate(-15deg);
}

.case .overlap-19 {
  width: 478px;
  height: 984px;
  position: absolute;
  top: 0;
  left: -123px;
}

.case .section-BG-image-3 {
  width: 181px;
  height: 567px;
  position: absolute;
  top: 55px;
  left: 70px;
  transform: rotate(-15deg);
}

.case .rectangle-4 {
  background-color: #fff;
  width: 354px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 123px;
}

.case .image-8 {
  object-fit: cover;
  width: 34px;
  height: 38px;
  position: absolute;
  top: 20px;
  left: 139px;
  transform: rotate(-15deg);
}

.case .frame-181 {
  background: linear-gradient(#1269b7 0%, #af62e5 100%);
  border-radius: 3.94px;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  padding: 7.88px 11.82px;
  display: inline-flex;
  position: absolute;
  top: 10px;
  left: 316px;
}

.case .frame-182 {
  flex-direction: column;
  align-items: center;
  gap: 27.57px;
  display: inline-flex;
  position: absolute;
  top: 173px;
  left: 139px;
}

.case .frame-183 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 13.79px;
  display: inline-flex;
  position: relative;
}

.case .frame-184 {
  background-color: #f6f6f61f;
  border-radius: 49.23px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  padding: 3.94px 7.88px;
  display: inline-flex;
  position: relative;
}

.case .type-18 {
  color: #fff;
  letter-spacing: -.2px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 9.8px;
  font-weight: 700;
  line-height: 13.8px;
  position: relative;
}

.case .frame-185 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 15.75px;
  width: 322.96px;
  display: flex;
  position: relative;
}

.case .text-wrapper-125 {
  color: #fff;
  letter-spacing: -.26px;
  text-align: center;
  width: 322.96px;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 25.6px;
  font-weight: 700;
  line-height: 30.7px;
  position: relative;
}

.case .buttons-13 {
  background-color: #0d0d0d;
  border-radius: 3.94px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  width: 215.64px;
  padding: 11.82px 23.63px;
  display: flex;
  position: relative;
}

.case .frame-186 {
  flex-direction: column;
  align-items: flex-start;
  gap: 23.63px;
  display: inline-flex;
  position: absolute;
  top: 587px;
  left: 139px;
}

.case .frame-187 {
  width: 55px;
  height: 55px;
  position: absolute;
  top: -5909px;
  left: -2218px;
  transform: rotate(-15deg);
}

.case .frame-188 {
  background-color: #0d0d0d;
  width: 354px;
  height: 603px;
  position: absolute;
  top: 4909px;
  left: 0;
  overflow: hidden;
}

.case .overlap-group-7 {
  width: 95px;
  height: 48px;
  position: absolute;
  top: -10727px;
  left: -2489px;
}

.case .mail-outline-black-5 {
  width: 23.63px;
  height: 23.63px;
  margin-top: -10932.9px;
  margin-left: -2526.68px;
  position: relative;
  transform: rotate(-15deg);
}

.case .place-black-4 {
  width: 23.63px;
  height: 23.63px;
  margin-top: -10972.3px;
  margin-left: -2526.68px;
  position: relative;
  transform: rotate(-15deg);
}

.case .vector-8 {
  width: 323px;
  height: 1px;
  position: absolute;
  top: -10250px;
  left: -2362px;
  transform: rotate(-15deg);
}

.case .CT-as-4 {
  background-image: url("section-01-image-01-1.bc340a45.png");
  background-position: 50%;
  background-size: cover;
  width: 354px;
  height: 209px;
  position: absolute;
  top: 4701px;
  left: 0;
}

.case .text-wrapper-126 {
  color: var(--neutrals-0);
  letter-spacing: -.23px;
  text-align: center;
  width: 188px;
  height: 54px;
  font-family: Public Sans, Helvetica;
  font-size: 22.6px;
  font-weight: 800;
  line-height: 27.2px;
  position: absolute;
  top: 30px;
  left: 83px;
}

.case .text-wrapper-127 {
  color: #fff;
  letter-spacing: -.07px;
  white-space: nowrap;
  height: 19px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: absolute;
  top: 96px;
  left: 97px;
}

.case .buttons-14 {
  background-color: var(--white);
  border: .98px solid #cfcfcf;
  border-radius: 3.94px;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  padding: 11.82px 15.75px;
  display: inline-flex;
  position: absolute;
  top: 137px;
  left: 122px;
  overflow: hidden;
}

.case .text-wrapper-128 {
  color: #3d3d3d;
  letter-spacing: -.28px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 700;
  line-height: 16.5px;
  position: relative;
}

.case .text-wrapper-129 {
  color: #606060;
  letter-spacing: -.24px;
  text-align: center;
  width: 323px;
  height: 88px;
  font-family: Public Sans, Helvetica;
  font-size: 15.8px;
  font-weight: 400;
  line-height: 22.1px;
  position: absolute;
  top: 2885px;
  left: 16px;
}

.case .text-wrapper-130 {
  color: #000;
  letter-spacing: -.23px;
  text-align: center;
  width: 323px;
  height: 27px;
  font-family: Public Sans, Helvetica;
  font-size: 22.6px;
  font-weight: 800;
  line-height: 27.2px;
  position: absolute;
  top: 2842px;
  left: 16px;
}

.case .overlap-20 {
  background-color: #e3edf7;
  width: 354px;
  height: 291px;
  position: absolute;
  top: 4409px;
  left: 0;
}

.case .buttons-15 {
  background-color: #0d0d0d;
  border-radius: 3.94px;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  width: 240px;
  padding: 11.82px 23.63px;
  display: flex;
  position: absolute;
  top: 199px;
  left: 57px;
}

.case .frame-189 {
  flex-direction: column;
  align-items: flex-start;
  gap: 11.82px;
  display: inline-flex;
  position: absolute;
  top: 39px;
  left: 57px;
}

.case .text-wrapper-131 {
  color: #1b1b1b;
  letter-spacing: -.18px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 17.7px;
  font-weight: 600;
  line-height: 21.3px;
  position: relative;
}

.case .text-wrapper-132 {
  color: #222;
  letter-spacing: -.07px;
  width: 240.25px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .frame-190 {
  flex-direction: column;
  align-items: flex-start;
  gap: 15.75px;
  display: inline-flex;
  position: absolute;
  top: 3011px;
  left: 16px;
}

.case .section-image-16 {
  object-fit: cover;
  width: 322.96px;
  height: 322.96px;
  margin-top: -8882.14px;
  margin-left: -2493.04px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-image-17 {
  object-fit: cover;
  width: 322.96px;
  height: 322.96px;
  margin-top: -8554.96px;
  margin-left: -2405.37px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-image-18 {
  object-fit: cover;
  width: 322.96px;
  height: 322.96px;
  margin-top: -8227.79px;
  margin-left: -2317.71px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-image-19 {
  object-fit: cover;
  width: 322.96px;
  height: 322.96px;
  margin-top: -7900.61px;
  margin-left: -2230.04px;
  position: relative;
  transform: rotate(-15deg);
}

.case .mobile-digited-2 {
  background-color: #fff;
  border: 8px solid #1269b752;
  border-radius: 13.89px;
  width: 370px;
  height: 5223px;
  position: absolute;
  top: 444px;
  left: 1216px;
  overflow: hidden;
  transform: rotate(15deg);
}

.case .overlap-21 {
  width: 498px;
  height: 725px;
  position: absolute;
  top: -3785px;
  left: -1656px;
}

.case .overlap-22 {
  width: 498px;
  height: 672px;
  position: absolute;
  top: 53px;
  left: 0;
}

.case .section-BG-image-4 {
  width: 354px;
  height: 601px;
  position: absolute;
  top: 36px;
  left: 72px;
  transform: rotate(-15deg);
}

.case .image-9 {
  object-fit: cover;
  width: 87px;
  height: 24px;
  position: absolute;
  top: 38px;
  left: 7px;
  transform: rotate(-15deg);
}

.case .frame-191 {
  width: 55px;
  height: 55px;
  position: absolute;
  top: 6px;
  left: 281px;
  transform: rotate(-15deg);
}

.case .overlap-23 {
  background-color: #fbfbfb;
  width: 354px;
  height: 1533px;
  position: absolute;
  top: 656px;
  left: 0;
}

.case .type-19 {
  color: #0000;
  letter-spacing: -.23px;
  text-align: center;
  width: 322.96px;
  font-family: Public Sans, Helvetica;
  font-size: 22.6px;
  font-weight: 800;
  line-height: 27.2px;
  position: relative;
}

.case .text-wrapper-133 {
  color: #0d0d0d;
}

.case .text-wrapper-134 {
  color: #af62e5;
}

.case .section-image-20 {
  width: 322.96px;
  height: 215.31px;
  margin-top: -4478.46px;
  margin-left: -1629.06px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-image-21 {
  width: 322.96px;
  height: 215.31px;
  margin-top: -4893.16px;
  margin-left: -1629.58px;
  position: relative;
  transform: rotate(-15deg);
}

.case .arrow-forward-5 {
  width: 19.69px;
  height: 15.75px;
  margin-top: -5573.64px;
  margin-left: -1595.77px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-image-22 {
  width: 322.96px;
  height: 215.31px;
  margin-top: -5238.67px;
  margin-left: -1612.09px;
  position: relative;
  transform: rotate(-15deg);
}

.case .overlap-24 {
  width: 354px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
}

.case .overlap-25 {
  width: 613px;
  height: 1126px;
  position: absolute;
  top: 3230px;
  left: -129px;
}

.case .text-container-5 {
  border-radius: 11.82px;
  width: 354px;
  height: 428px;
  position: absolute;
  top: 698px;
  left: 129px;
  overflow: hidden;
}

.case .frame-192 {
  width: 34px;
  height: 30px;
  position: absolute;
  top: 48px;
  left: 160px;
  transform: rotate(-15deg);
}

.case .ellipse-7 {
  object-fit: cover;
  width: 44px;
  height: 44px;
  position: absolute;
  top: 1px;
  left: 1px;
  transform: rotate(-15deg);
}

.case .text-wrapper-135 {
  color: #0d0d0d;
  letter-spacing: -.23px;
  text-align: center;
  width: 323px;
  height: 27px;
  font-family: Public Sans, Helvetica;
  font-size: 22.6px;
  font-weight: 800;
  line-height: 27.2px;
  position: absolute;
  top: 0;
  left: 145px;
}

.case .overlap-26 {
  width: 613px;
  height: 715px;
  position: absolute;
  top: 18px;
  left: 0;
}

.case .text-wrapper-136 {
  color: #606060;
  letter-spacing: -.24px;
  text-align: center;
  width: 323px;
  font-family: Public Sans, Helvetica;
  font-size: 15.8px;
  font-weight: 400;
  line-height: 22.1px;
  position: absolute;
  top: 21px;
  left: 145px;
}

.case .section-main-11 {
  width: 470px;
  height: 614px;
  position: absolute;
  top: 50px;
  left: 72px;
  transform: rotate(-15deg);
}

.case .frame-193 {
  background-color: #0d0d0d;
  width: 354px;
  height: 603px;
  position: absolute;
  top: 4604px;
  left: 0;
  overflow: hidden;
}

.case .overlap-group-8 {
  width: 95px;
  height: 48px;
  position: absolute;
  top: -8291px;
  left: -1646px;
}

.case .company-links-5 {
  width: 98px;
  height: 152px;
  position: absolute;
  top: 330px;
  left: 16px;
}

.case .mail-outline-black-6 {
  width: 23.63px;
  height: 23.63px;
  margin-top: -8497.28px;
  margin-left: -1683.56px;
  position: relative;
  transform: rotate(-15deg);
}

.case .place-black-5 {
  width: 23.63px;
  height: 23.63px;
  margin-top: -8536.67px;
  margin-left: -1683.56px;
  position: relative;
  transform: rotate(-15deg);
}

.case .vector-9 {
  width: 323px;
  height: 1px;
  position: absolute;
  top: -7815px;
  left: -1519px;
  transform: rotate(-15deg);
}

.case .CT-as-5 {
  background-image: url("ctas.981fe633.png");
  background-position: 50%;
  background-size: cover;
  width: 354px;
  height: 209px;
  position: absolute;
  top: 4395px;
  left: 0;
}

.case .frame-194 {
  flex-direction: column;
  align-items: center;
  gap: 13.79px;
  padding: 0 0 36.43px;
  display: inline-flex;
  position: absolute;
  top: 2228px;
  left: 16px;
}

.case .frame-195 {
  flex-direction: column;
  align-items: center;
  gap: 39.39px;
  display: inline-flex;
  position: absolute;
  top: 2383px;
  left: 21px;
}

.case .frame-196 {
  flex: none;
  align-items: flex-start;
  gap: 11.82px;
  width: 313.12px;
  display: flex;
  position: relative;
}

.case .section-icon-8 {
  width: 38.59px;
  height: 38.59px;
  margin-top: -3.54px;
  margin-left: -3.54px;
  position: relative;
  transform: rotate(-15deg);
}

.case .frame-197 {
  flex-direction: column;
  align-items: flex-start;
  gap: 7.88px;
  width: 269.79px;
  display: flex;
  position: relative;
}

.case .type-20 {
  color: #0d0d0d;
  letter-spacing: -.16px;
  width: 269.79px;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 15.8px;
  font-weight: 500;
  line-height: 18.9px;
  position: relative;
}

.case .text-wrapper-137 {
  color: #858585;
  letter-spacing: -.07px;
  width: 269.79px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: relative;
}

.case .frame-198 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 27.57px;
  width: 323px;
  display: flex;
  position: absolute;
  top: 185px;
  left: 16px;
}

.case .text-wrapper-138 {
  color: #fff;
  letter-spacing: -.23px;
  text-align: center;
  width: 236.31px;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 22.6px;
  font-weight: 800;
  line-height: 27.2px;
  position: relative;
}

.case .buttons-16 {
  all: unset;
  box-sizing: border-box;
  background: linear-gradient(#1269b7 0%, #af62e5 100%);
  border-radius: 3.94px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  width: 181.17px;
  padding: 11.82px 15.75px;
  display: flex;
  position: relative;
}

.case .mobile-digited-3 {
  background-color: #fff;
  border: 8px solid #1269b752;
  border-radius: 13.89px;
  width: 370px;
  height: 4677px;
  position: absolute;
  top: 3682px;
  left: 856px;
  overflow: hidden;
  transform: rotate(15deg);
}

.case .overlap-27 {
  width: 658px;
  height: 2229px;
  position: absolute;
  top: -33px;
  left: -152px;
}

.case .rectangle-5 {
  background-color: #fbfbfb;
  width: 354px;
  height: 1533px;
  position: absolute;
  top: 696px;
  left: 152px;
}

.case .section-main-12 {
  width: 500px;
  height: 679px;
  position: absolute;
  top: 53px;
  left: 79px;
  transform: rotate(-15deg);
}

.case .rectangle-6 {
  background-color: #fff;
  width: 354px;
  height: 55px;
  position: absolute;
  top: 33px;
  left: 152px;
}

.case .image-10 {
  object-fit: cover;
  width: 90px;
  height: 45px;
  position: absolute;
  top: 38px;
  left: 166px;
  transform: rotate(-15deg);
}

.case .frame-199 {
  width: 68px;
  height: 68px;
  position: absolute;
  top: 27px;
  left: 445px;
  transform: rotate(-15deg);
}

.case .frame-200 {
  background: linear-gradient(#1269b7 0%, #af62e5 100%);
  border-radius: 3.94px;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  padding: 7.88px 11.82px;
  display: inline-flex;
  position: absolute;
  top: 43px;
  left: 345px;
}

.case .frame-201 {
  flex-direction: column;
  align-items: center;
  gap: 13.79px;
  padding: 0 0 36.43px;
  display: inline-flex;
  position: absolute;
  top: 736px;
  left: 168px;
}

.case .frame-202 {
  flex-direction: column;
  align-items: center;
  gap: 63.02px;
  display: inline-flex;
  position: absolute;
  top: 882px;
  left: 168px;
}

.case .arrow-forward-6 {
  width: 23.1px;
  height: 20.31px;
  margin-top: -2.28px;
  margin-bottom: -2.28px;
  margin-right: -1.7px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-image-23 {
  width: 367.68px;
  height: 55.83px;
  margin-left: -52.87px;
  position: relative;
  transform: rotate(-15deg);
}

.case .arrow-forward-7 {
  width: 19.69px;
  height: 15.75px;
  margin-top: -8382.12px;
  margin-left: -2010.04px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-image-24 {
  width: 322.96px;
  height: 215.31px;
  margin-top: -8120.3px;
  margin-left: -2041.99px;
  position: relative;
  transform: rotate(-15deg);
}

.case .arrow-forward-8 {
  width: 19.69px;
  height: 15.75px;
  margin-top: -8841.46px;
  margin-left: -2010.04px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-image-25 {
  width: 322.96px;
  height: 215.31px;
  margin-top: -8542.29px;
  margin-left: -2037.07px;
  position: relative;
  transform: rotate(-15deg);
}

.case .frame-203 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 27.57px;
  width: 323px;
  display: flex;
  position: absolute;
  top: 189px;
  left: 168px;
}

.case .buttons-17 {
  background: linear-gradient(#1269b7 0%, #af62e5 100%);
  border-radius: 3.94px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  width: 181.17px;
  padding: 11.82px 15.75px;
  display: flex;
  position: relative;
}

.case .text-container-6 {
  border-radius: 11.82px;
  width: 354px;
  height: 428px;
  position: absolute;
  top: 3305px;
  left: 0;
  overflow: hidden;
}

.case .frame-204 {
  width: 29px;
  height: 24px;
  position: absolute;
  top: -10225px;
  left: -1918px;
  transform: rotate(-15deg);
}

.case .ellipse-8 {
  object-fit: cover;
  width: 44px;
  height: 44px;
  position: absolute;
  top: -10546px;
  left: -2150px;
  transform: rotate(-15deg);
}

.case .frame-205 {
  background-color: #0d0d0d;
  width: 354px;
  height: 603px;
  position: absolute;
  top: 4059px;
  left: 0;
  overflow: hidden;
}

.case .overlap-group-9 {
  width: 95px;
  height: 48px;
  position: absolute;
  top: -10975px;
  left: -2066px;
}

.case .mail-outline-black-7 {
  width: 23.63px;
  height: 23.63px;
  margin-top: -11181.6px;
  margin-left: -2103.63px;
  position: relative;
  transform: rotate(-15deg);
}

.case .place-black-6 {
  width: 23.63px;
  height: 23.63px;
  margin-top: -11221px;
  margin-left: -2103.63px;
  position: relative;
  transform: rotate(-15deg);
}

.case .vector-10 {
  width: 323px;
  height: 1px;
  position: absolute;
  top: -10499px;
  left: -1939px;
  transform: rotate(-15deg);
}

.case .section-BG-image-5 {
  width: 354px;
  height: 286px;
  position: absolute;
  top: -3329px;
  left: -1083px;
  transform: rotate(-15deg);
}

.case .text-wrapper-139 {
  color: var(--neutrals-0);
  letter-spacing: -.23px;
  text-align: center;
  width: 188px;
  height: 54px;
  font-family: Public Sans, Helvetica;
  font-size: 22.6px;
  font-weight: 800;
  line-height: 27.2px;
  position: absolute;
  top: 3813px;
  left: 83px;
}

.case .find-out-how-it-can-2 {
  color: #fff;
  letter-spacing: -.07px;
  text-align: center;
  width: 256px;
  height: 57px;
  font-family: Public Sans, Helvetica;
  font-size: 13.8px;
  font-weight: 400;
  line-height: 19.3px;
  position: absolute;
  top: 3879px;
  left: 49px;
}

.case .buttons-18 {
  background-color: var(--white);
  border: .98px solid #cfcfcf;
  border-radius: 3.94px;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  padding: 11.82px 15.75px;
  display: inline-flex;
  position: absolute;
  top: 3968px;
  left: 122px;
  overflow: hidden;
}

.case .frame-206 {
  flex-direction: column;
  align-items: center;
  gap: 13.79px;
  padding: 0 0 36.43px;
  display: inline-flex;
  position: absolute;
  top: 2235px;
  left: 16px;
}

.case .frame-207 {
  flex-direction: column;
  align-items: center;
  gap: 39.39px;
  display: inline-flex;
  position: absolute;
  top: 2362px;
  left: 21px;
}

.case .section-icon-9 {
  width: 31.51px;
  height: 31.51px;
  margin-top: -9290.01px;
  margin-left: -2107.67px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-icon-10 {
  width: 31.51px;
  height: 31.51px;
  margin-top: -9451.27px;
  margin-left: -2107.67px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-icon-11 {
  width: 31.51px;
  height: 31.51px;
  margin-top: -9612.53px;
  margin-left: -2107.67px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-icon-12 {
  width: 31.51px;
  height: 31.51px;
  margin-top: -9773.79px;
  margin-left: -2107.67px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-icon-13 {
  width: 31.51px;
  height: 31.51px;
  margin-top: -9935.06px;
  margin-left: -2107.67px;
  position: relative;
  transform: rotate(-15deg);
}

.case .section-icon-14 {
  width: 31.51px;
  height: 31.51px;
  margin-top: -10058.3px;
  margin-left: -2107.67px;
  position: relative;
  transform: rotate(-15deg);
}

.case .mobile-contact-us {
  background-color: #fff;
  border: 8px solid #1269b752;
  border-radius: 13.89px;
  width: 370px;
  height: 903px;
  position: absolute;
  top: 2811px;
  left: 1598px;
  overflow: hidden;
  transform: rotate(15deg);
}

.case .overlap-28 {
  width: 770px;
  height: 1540px;
  position: relative;
  top: -33px;
  left: -208px;
}

.case .rectangle-7 {
  background-color: #fff;
  width: 354px;
  height: 55px;
  position: absolute;
  top: 33px;
  left: 208px;
}

.case .image-11 {
  object-fit: cover;
  width: 90px;
  height: 45px;
  position: absolute;
  top: 38px;
  left: 222px;
  transform: rotate(-15deg);
}

.case .frame-208 {
  width: 68px;
  height: 68px;
  position: absolute;
  top: 27px;
  left: 501px;
  transform: rotate(-15deg);
}

.case .frame-209 {
  background: linear-gradient(#1269b7 0%, #af62e5 100%);
  border-radius: 3.94px;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  padding: 7.88px 11.82px;
  display: inline-flex;
  position: absolute;
  top: 43px;
  left: 401px;
}

.case .frame-210 {
  background-color: #0d0d0d;
  width: 354px;
  height: 603px;
  position: absolute;
  top: 937px;
  left: 208px;
  overflow: hidden;
}

.case .overlap-group-10 {
  width: 96px;
  height: 59px;
  position: absolute;
  top: 13px;
  left: 14px;
}

.case .image-12 {
  object-fit: cover;
  width: 90px;
  height: 37px;
  position: absolute;
  top: 11px;
  left: 3px;
  transform: rotate(-15deg);
}

.case .mask-group-3 {
  width: 90px;
  height: 37px;
  position: absolute;
  top: 11px;
  left: 3px;
  transform: rotate(-15deg);
}

.case .mail-outline-black-8 {
  width: 23.63px;
  height: 23.63px;
  margin-top: -7057.77px;
  margin-left: -2106.07px;
  position: relative;
  transform: rotate(-15deg);
}

.case .place-black-7 {
  width: 23.63px;
  height: 23.63px;
  margin-top: -7097.15px;
  margin-left: -2106.07px;
  position: relative;
  transform: rotate(-15deg);
}

.case .vector-11 {
  width: 323px;
  height: 1px;
  position: absolute;
  top: -6375px;
  left: -1942px;
  transform: rotate(-15deg);
}

.case .section-BG-main-3 {
  width: 558px;
  height: 895px;
  position: absolute;
  top: 57px;
  left: 106px;
  transform: rotate(-15deg);
}

.case .frame-211 {
  flex-direction: column;
  align-items: flex-start;
  gap: 23.63px;
  width: 323px;
  display: flex;
  position: absolute;
  top: 148px;
  left: 224px;
}

.case .text-wrapper-140 {
  color: #0d0d0d;
  letter-spacing: -.26px;
  width: 322.96px;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 25.6px;
  font-weight: 700;
  line-height: 30.7px;
  position: relative;
}

.case .text-wrapper-141 {
  color: #606060;
  letter-spacing: -.24px;
  width: 322.96px;
  font-family: Public Sans, Helvetica;
  font-size: 15.8px;
  font-weight: 400;
  line-height: 22.1px;
  position: relative;
}

.case .frame-212 {
  background-color: #fff;
  border-radius: 3.94px;
  align-items: flex-start;
  gap: 9.85px;
  width: 323px;
  padding: 39.39px 23.63px;
  display: flex;
  position: absolute;
  top: 367px;
  left: 224px;
  overflow: hidden;
  box-shadow: 0 1.97px 7.88px .98px #0000001a;
}

.case .frame-213 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 47.26px;
  display: flex;
  position: relative;
}

.case .frame-214 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 23.63px;
  width: 100%;
  display: flex;
  position: relative;
}

.case .frame-215 {
  background-color: #fff;
  border: .98px solid #cfcfcf;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 9.85px;
  width: 100%;
  padding: 11.82px 15.75px;
  display: flex;
  position: relative;
}

.case .div-5 {
  color: #0000;
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 11.8px;
  font-weight: 400;
  line-height: 16.5px;
  position: relative;
}

.case .text-wrapper-142 {
  color: #00000080;
  letter-spacing: -.06px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.98px;
  font-family: Public Sans, Helvetica;
  font-size: 11.8px;
  font-weight: 400;
  line-height: 16.5px;
  position: relative;
}

.case .frame-216 {
  background-color: #fff;
  border: .98px solid #cfcfcf;
  align-self: stretch;
  align-items: flex-start;
  gap: 9.85px;
  width: 100%;
  height: 165.42px;
  padding: 11.82px 15.75px;
  display: flex;
  position: relative;
}

.case .buttons-19 {
  background-color: #0d0d0d;
  border-radius: 3.94px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 9.85px;
  width: 275.7px;
  padding: 11.82px 23.63px;
  display: flex;
  position: relative;
}

.case .overlap-wrapper {
  width: 1920px;
  height: 540px;
  position: absolute;
  top: 16321px;
  left: 0;
}

.case .overlap-29 {
  background-image: url("rectangle-122.62ade30e.svg");
  background-size: 100% 100%;
  height: 498px;
  position: relative;
}

.case .mask-group-4 {
  width: 1920px;
  height: 498px;
  position: absolute;
  top: 0;
  left: 0;
}

.case .frame-217 {
  background-color: #fff3f0;
  flex-direction: column;
  align-items: flex-start;
  gap: 120px;
  width: 1920px;
  padding: 120px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.case .frame-218 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.case .heading {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 32px;
  width: 1181px;
  display: flex;
  position: relative;
}

.case .text-wrapper-143 {
  color: #000;
  letter-spacing: -.48px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 96px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.case .text-wrapper-144 {
  color: #000;
  letter-spacing: -.16px;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.case .group-3 {
  width: 170px;
  height: 108px;
  position: relative;
}

.case .UIX-wrapper {
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: absolute;
  top: 52px;
  left: 0;
}

.case .UIX {
  width: 168px;
  height: 56px;
  position: relative;
}

.case .frame-219 {
  background-color: #a7a7a71f;
  border-radius: 28px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 16px 4px 4px;
  display: inline-flex;
  position: relative;
}

.case .frame-220 {
  flex: none;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.case .group-4 {
  background-color: #fff;
  border-radius: 24px;
  width: 48px;
  height: 48px;
  position: relative;
}

.case .overlap-group-11 {
  width: 22px;
  height: 22px;
  position: relative;
  top: 13px;
  left: 13px;
}

.case .line {
  width: 5px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 9px;
}

.case .line-2 {
  width: 22px;
  height: 4px;
  position: absolute;
  top: 9px;
  left: 0;
}

.case .text-wrapper-145 {
  color: #000;
  letter-spacing: .46px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Display Pro-75Bold, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.9px;
  position: relative;
}

.case .text-wrapper-146 {
  color: #000;
  letter-spacing: -.39px;
  white-space: nowrap;
  height: 40px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

.case .frame-221 {
  background: linear-gradient(#8064d7 47.99%);
  width: 1920px;
  height: 1020px;
  position: absolute;
  top: 656px;
  left: 0;
  overflow: hidden;
}

.case .rectangle-8 {
  width: 1920px;
  height: 1057px;
  position: absolute;
  top: 6891px;
  left: 960px;
}

.case .overlap-30 {
  width: 1550px;
  height: 1020px;
  position: absolute;
  top: 0;
  left: 178px;
}

.case .home-page-2 {
  width: 777px;
  height: 1020px;
  position: absolute;
  top: 0;
  left: 773px;
}

.case .home-page-3 {
  width: 884px;
  height: 956px;
  position: absolute;
  top: 64px;
  left: 0;
}

.case .frame-222 {
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 56px;
  width: 1919px;
  padding: 100px 120px;
  display: flex;
  position: absolute;
  top: 15830px;
  left: 0;
}

.case .we-are-in-love-with {
  color: #000;
  letter-spacing: -.8px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 40px;
  font-weight: 400;
  line-height: 56px;
  position: relative;
}

.case .frame-223 {
  flex: none;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.case .frame-224 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.case .text-wrapper-147 {
  color: #000;
  letter-spacing: -.56px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.case .text-wrapper-148 {
  color: #979797;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Text Pro-55Roman, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.case .text-wrapper-149 {
  color: #0d99ff;
  letter-spacing: -.48px;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Neue Haas Grotesk Text Pro-65Medium, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  text-decoration: underline;
  position: relative;
}

/*# sourceMappingURL=index.5e919c69.css.map */
