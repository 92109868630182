.property-wrapper {
  height: 34px;
  overflow: hidden;
}

.property-wrapper .group {
  height: 134px;
  position: relative;
  width: 134px;
}

.property-wrapper .text-wrapper {
  color: #0d0d0d;
  font-family: "Public Sans", Helvetica;
  font-size: 28px;
  font-weight: 700;
  height: 34px;
  left: 0;
  letter-spacing: -0.7px;
  line-height: 33.6px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.property-wrapper .div {
  color: #0d0d0d;
  font-family: "Public Sans", Helvetica;
  font-size: 28px;
  font-weight: 700;
  height: 34px;
  left: 0;
  letter-spacing: -0.7px;
  line-height: 33.6px;
  position: absolute;
  top: 50px;
  white-space: nowrap;
}

.property-wrapper .text-wrapper-2 {
  color: #0d0d0d;
  font-family: "Public Sans", Helvetica;
  font-size: 28px;
  font-weight: 700;
  height: 34px;
  left: 0;
  letter-spacing: -0.7px;
  line-height: 33.6px;
  position: absolute;
  top: 100px;
  white-space: nowrap;
}

.property-wrapper.one {
  width: 88px;
}

.property-wrapper.three {
  width: 128px;
}

.property-wrapper.two {
  width: 114px;
}

.property-wrapper.four {
  width: 88px;
}

.property-wrapper.one .group {
  top: -100px;
}

.property-wrapper.three .group {
  top: -50px;
}

.property-wrapper.four .group {
  top: -100px;
}
