.component {
  align-items: center;
  border-radius: 8px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 8px 16px 8px 20px;
  position: relative;
}

.component .home {
  font-family: "Public Sans", Helvetica;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.component .expand-more-black {
  height: 16px;
  position: relative;
  width: 16px;
}

.component.frame-140 {
  background-color: #e7e7e7bf;
}

.component.frame-141 {
  background-color: #f5ebfcbf;
}

.component.frame-140 .home {
  color: #0d0d0d;
  font-weight: 400;
}

.component.frame-137 .home {
  color: #0d0d0d;
  font-weight: 400;
}

.component.frame-141 .home {
  color: #7c2fb2;
  font-weight: 500;
}
